import React, { useState, useEffect } from "react";
import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import logo from "../media/logo.svg";
import { api } from "../utils/api";
import { TeamContext } from "../utils/TeamContext";
import { UserContext } from "../utils/UserContext";

export default function NavBar() {
  const userContext = React.useContext(UserContext);
  const teamContext = React.useContext(TeamContext);
  const navigator = useNavigate();

  return (
    <Navbar
      bg="white"
      expand="lg"
      onSelect={(eventKey) => {
        navigator(eventKey);
      }}
    >
      <Container>
        <Navbar.Brand
          onClick={() => {
            navigator("/my_orders");
          }}
          style={{ cursor: "pointer" }}
        >
          <img alt="Лого" src={logo} style={{ width: 150 }} />
          <p
            className="m-0 fw-bold text-primary ms-2"
            style={{
              maxWidth: 148,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {teamContext.name}
          </p>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="me-auto">
            {userContext.access.ordersMy ? (
              <Nav.Link eventKey="/my_orders">Моя еда</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.ordersAll ? (
              <Nav.Link eventKey="/all_orders">Вся еда</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.exchange ? (
              <Nav.Link eventKey="/exchanges">Аукцион</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.application ? (
              <Nav.Link eventKey="/applications">Заявки</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.meals ? (
              <Nav.Link eventKey="/meals">Блюда</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.report ? (
              <Nav.Link eventKey="/report">Отчет</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.limits ? (
              <Nav.Link eventKey="/limits">Лимиты</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.fridge ? (
              <Nav.Link eventKey="/fridge">Холодильник</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.users ? (
              <Nav.Link eventKey="/users">Пользователи</Nav.Link>
            ) : (
              ""
            )}
            {userContext.access.isTeamAdmin ? (
              <Nav.Link eventKey="/team">Команда</Nav.Link>
            ) : (
              ""
            )}
            {userContext.isRoot ? (
              <Nav.Link eventKey="/teams">Команды</Nav.Link>
            ) : (
              ""
            )}
          </Nav>

          <Nav className="d-flex justify-content-end">
            <Nav.Link eventKey="/me">{userContext.name}</Nav.Link>
            {document.cookie.match(/inspiro_root/) ? (
              <Nav.Link onClick={(e) => api.rootLogout()}>
                Войти в себя
              </Nav.Link>
            ) : (
              ""
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
