import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  Button,
  Table,
  Popover,
  OverlayTrigger,
  InputGroup,
  Tooltip,
} from "react-bootstrap";
import { api } from "../utils/api";
import Menu from "./Menu";
import { UserContext } from "../utils/UserContext";
import ExchangePopup from "./ExchangePopup";

const AllOrderPopup = ({
  activeOrder,
  id,
  setActiveOrder,
  date,
  setNeedUpdate,
}) => {
  const [form, setForm] = useState({
    date: new Date(),
    user: "",
    status: "",
    total: 0,
    meals: [],
    _id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [users, setUsers] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [mealToExchange, setMealToExchange] = useState(null);
  const [needUpdateLocal, setNeedUpdateLocal] = useState(true);
  const userContext = React.useContext(UserContext);
  const [limitType, setLimitType] = useState(null);
  const [cpfc, setCpfc] = useState(null);
  const [totalCpfc, setTotalCpfc] = useState(null);
  const [menu, setMenu] = useState(null);
  const [amountToPay, setAmountToPay] = useState(0);

  const closePopup = () => {
    setForm({
      date: new Date(),
      user: "",
      status: "",
      total: 0,
      meals: [],
      _id: "",
    });
    setActiveOrder(null);
    setNeedUpdate(true);
    setNeedUpdateLocal(true);
    setCpfc(null);
    setTotalCpfc(null);
  };

  useEffect(() => {
    if (!activeOrder || !needUpdateLocal) return;
    if (activeOrder.new === "new") {
      setForm({ ...form, date: activeOrder.date, user: activeOrder.user });
      return;
    }
    api
      .getOrder(activeOrder)
      .then(({ order, limitType }) => {
        order && setForm(order);
        setLimitType(limitType);
      })
      .finally(() => setNeedUpdateLocal(false));
  }, [activeOrder, needUpdateLocal]);

  const onSubmit = () => {
    setIsLoading(true);
    const { _id, meals, date, user } = form;
    if (_id === "") {
      api
        .postOrder({ meals, date, user })
        .then(() => {
          setNeedUpdate(true);
          closePopup();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      api
        .editOrder({ meals, date, _id, user })
        .then(() => {
          setNeedUpdate(true);
          closePopup();
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    setIsLoading(true);
    api
      .deleteOrder(form._id)
      .then(() => {
        setNeedUpdate(true);
        closePopup();
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const mealAdd = ({ name, price, mealId }) => {
    if (form.meals.find((el) => el.mealId === mealId))
      setForm({
        ...form,
        meals: form.meals((el) => {
          if (el.mealId === mealId) {
            return {
              ...el,
              amount: el.amount + 1,
            };
          } else {
            return el;
          }
        }),
      });
    else
      setForm({
        ...form,
        meals: [...form.meals, { name, price, mealId, amount: 1 }],
      });
    setIsMenuOpen(false);
  };

  const mealRemove = (mealId) => {
    setForm({
      ...form,
      meals: form.meals.filter((meal) => meal.mealId !== mealId),
    });
    setIsMenuOpen(false);
  };

  const mealSetAmount = (index, amount) => {
    if (amount <= 0) return;
    setForm({
      ...form,
      meals: form.meals.map((le, ind) => {
        if (index === ind) return { ...le, amount: amount };
        else return le;
      }),
    });
  };

  useEffect(() => {
    let total = 0;
    form.meals.forEach((meal) => (total += meal.price * meal.amount));
    setForm({ ...form, total });
  }, [form.meals]);

  useEffect(() => {
    api.getUsers({ p: 1, s: 1000 }).then(({ users }) => setUsers(users));
  }, []);

  const payForMeal = (mealId, zero) => {
    api
      .payForMeal({
        orderId: form._id,
        mealId,
        amount: zero === 0 ? 0 : amountToPay,
      })
      .then(() => setNeedUpdateLocal(true));
  };

  useEffect(() => {
    api.getMeals({ p: 1, s: 10000 }).then(({ meals }) => setMenu(meals));
  }, []);

  useEffect(() => {
    if (!menu) return;
    if (form.meals.length <= 0) return;
    let Cc = 0,
      P = 0,
      F = 0,
      C = 0;
    setCpfc(
      form.meals.map((el) => {
        if (menu.find((le) => le._id === el.mealId)) {
          const {
            calories,
            proteins,
            fats,
            carbohydrates,
            weight,
            weightUnit,
          } = menu.find((le) => le._id === el.mealId);
          if (
            !Boolean(calories) ||
            !Boolean(proteins) ||
            !Boolean(fats) ||
            !Boolean(carbohydrates) ||
            !Boolean(weight) ||
            !Boolean(weightUnit)
          )
            return null;
          Cc += Number(calories.toFixed(2)) * el.amount;
          P +=
            weightUnit === "Вес, г"
              ? Number(((proteins / 100) * weight).toFixed(2)) * el.amount
              : Number(proteins.toFixed(2));
          F +=
            weightUnit === "Вес, г"
              ? Number(((fats / 100) * weight).toFixed(2)) * el.amount
              : Number(fats.toFixed(2));
          C +=
            weightUnit === "Вес, г"
              ? Number(((carbohydrates / 100) * weight).toFixed(2)) * el.amount
              : Number(carbohydrates.toFixed(2));
          return {
            calories: calories.toFixed(2) * el.amount,
            proteins:
              weightUnit === "Вес, г"
                ? ((proteins / 100) * weight).toFixed(2) * el.amount
                : proteins.toFixed(2),
            fats:
              weightUnit === "Вес, г"
                ? ((fats / 100) * weight).toFixed(2) * el.amount
                : fats.toFixed(2),
            carbohydrates:
              weightUnit === "Вес, г"
                ? ((carbohydrates / 100) * weight).toFixed(2) * el.amount
                : carbohydrates.toFixed(2),
            weight,
            weightUnit,
          };
        } else {
          return null;
        }
      })
    );
    setTotalCpfc({ Cc, P, F, C });
  }, [form]);

  return (
    <>
      <Modal show={activeOrder ? true : false} onHide={() => closePopup()}>
        <Modal.Header closeButton>
          <Modal.Title>
            {new Date(form.date).toLocaleDateString("ru-RU", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Статус</Form.Label>
              <Form.Select value={form.status} disabled={true}>
                <option value="notOrdered">Не заказано</option>
                <option value="ordered">Заказано</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Имя</Form.Label>
              <Form.Select value={form.user} disabled={true}>
                {users.map((el) => (
                  <option value={el._id} key={el._id}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th width="60%">Название</th>
                <th width="12%">Цена</th>
                <th width="15%">К-во</th>
                <th width="12%">Сумма</th>
              </tr>
            </thead>
            <tbody>
              {form.meals.map((meal, index) => (
                <tr
                  key={meal._id}
                  className={meal.onChangeAmount > 0 ? "bg-primary" : ""}
                >
                  <td>
                    <div>
                      {meal.name}
                      {userContext.access.editOrderedOrders ||
                      form.status !== "ordered" ? (
                        <i
                          class="bi bi-x-lg ms-2"
                          onClick={() => mealRemove(meal.mealId)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        ""
                      )}
                      {userContext.access.exchange &
                      (meal.onChangeAmount <= 0) &
                      (form.status === "ordered") ? (
                        <i
                          class="bi bi-upload ms-2"
                          onClick={() =>
                            setMealToExchange({
                              orderId: form._id,
                              name: meal.name,
                              price: meal.price,
                              mealId: meal.mealId,
                              amount: meal.amount,
                            })
                          }
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        ""
                      )}
                      {limitType === "meals" & form.status === "ordered" ? (
                        meal.amountToPay <= 0 ? (
                          <OverlayTrigger
                            trigger="click"
                            placement="top"
                            onToggle={(e) => setAmountToPay(1)}
                            overlay={
                              <Popover>
                                <Popover.Body className="d-flex flex-column align-items-center">
                                  <InputGroup size="lg">
                                    <Button

                                      onClick={(e) => {
                                        if (amountToPay - 1 > 0)
                                          setAmountToPay(amountToPay - 1);
                                      }}
                                      variant="outline-secondary"
                                      className="fs-3"
                                    >
                                      -
                                    </Button>
                                    <Form.Control
                                      value={amountToPay}
                                      onChange={(e) => {
                                        if (
                                          (e.target.value > 0) &
                                          (e.target.value <= meal.amount)
                                        )
                                          setAmountToPay(e.target.value);
                                      }}
                                      style={{
                                        display: "flex",
                                        width: 50,
                                        textAlign: "center",
                                      }}
                                    />
                                    <Button
                                      variant="outline-secondary"
                                      className="fs-3"
                                      onClick={(e) => {
                                        if (amountToPay + 1 <= meal.amount)
                                          setAmountToPay(amountToPay + 1);
                                      }}
                                    >
                                      +
                                    </Button>
                                  </InputGroup>
                                  <Button
                                    variant="primary"
                                    className="fs-4 mt-2"
                                    onClick={(e) => payForMeal(meal._id)}
                                  >
                                    Ок
                                  </Button>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <i
                              class="bi bi-credit-card-fill ms-2 p-1"
                              style={{
                                cursor:
                                  meal.amountToPay < 0
                                    ? "pointer"
                                    : userContext.access.cancelToPay
                                    ? "pointer"
                                    : "inherit",
                                backgroundColor:
                                  meal.amountToPay > 0
                                    ? "#28C76F"
                                    : "transparent",
                                color:
                                  meal.amountToPay > 0 ? "#fff" : "#28C76F",
                                borderRadius: "20%",
                              }}
                              title="Отметить, что я буду сам платить за это блюдо"
                            />
                          </OverlayTrigger>
                        ) : (
                          <i
                            class="bi bi-credit-card-fill ms-2 p-1"
                            title={userContext.access.cancelToPay ? "Отметить, что я не буду сам платить за это блюдо" : ""}
                            onClick={() => {
                              if (userContext.access.cancelToPay)
                                payForMeal(meal._id, 0);
                            }}
                            style={{
                              cursor:
                                meal.amountToPay < 0
                                  ? "pointer"
                                  : userContext.access.cancelToPay
                                  ? "pointer"
                                  : "inherit",
                              backgroundColor:
                                meal.amountToPay > 0
                                  ? "#28C76F"
                                  : "transparent",
                              color: meal.amountToPay > 0 ? "#fff" : "#28C76F",
                              borderRadius: "20%",
                            }}
                          />
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="fs-5">
                      {menu.find((le) => le._id === meal.mealId)?.subName}
                    </div>
                    <div
                      colspan={3}
                      className="text-left text-lowercase text-muted"
                      style={{ fontSize: 13 }}
                    >
                      {cpfc && cpfc[index]
                        ? cpfc[index].weightUnit === "Вес, г"
                          ? `ккал: ${cpfc[index].calories}  |  белков: ${cpfc[index].proteins}  |  жиров: ${cpfc[index].fats}  |  углеводов: ${cpfc[index].carbohydrates}  |  ${cpfc[index].weightUnit} ${cpfc[index].weight}`
                          : `ккал: ${cpfc[index].calories}  |  белков на 100гр.: ${cpfc[index].proteins}  |  жиров на 100гр.: ${cpfc[index].fats}  |  углеводов на 100гр.: ${cpfc[index].carbohydrates}  |  ${cpfc[index].weightUnit} ${cpfc[index].weight}`
                        : ""}
                    </div>
                  </td>
                  <td>{meal.price}</td>
                  {userContext.access.editOrderedOrders ||
                  form.status !== "ordered" ? (
                    <td
                      className="text-decoration-underline text-center"
                      style={{ cursor: "pointer" }}
                    >
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        overlay={
                          <Popover>
                            <Popover.Body className="d-flex justify-content-between">
                              <InputGroup size="lg">
                                <Button
                                  onClick={(e) =>
                                    mealSetAmount(
                                      index,
                                      Number(meal.amount) - 1
                                    )
                                  }
                                  variant="outline-secondary"
                                  className="fs-3"
                                >
                                  -
                                </Button>
                                <Form.Control
                                  value={meal.amount}
                                  onChange={(e) =>
                                    mealSetAmount(index, e.target.value)
                                  }
                                  style={{
                                    display: "flex",
                                    width: 50,
                                    textAlign: "center",
                                  }}
                                />
                                <Button
                                  variant="outline-secondary"
                                  className="fs-3"
                                  onClick={(e) =>
                                    mealSetAmount(
                                      index,
                                      Number(meal.amount) + 1
                                    )
                                  }
                                >
                                  +
                                </Button>
                              </InputGroup>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <p className="m-0">{meal.amount}</p>
                      </OverlayTrigger>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        onToggle={(e) => setAmountToPay(1)}
                        overlay={
                          <Tooltip placement="bottom">
                            Кол-во на аукционе / кол-во для оплаты
                          </Tooltip>
                        }
                      >
                        <p className="m-0">
                          ({meal.onChangeAmount}/{meal.amountToPay})
                        </p>
                      </OverlayTrigger>
                    </td>
                  ) : (
                    <td>
                      <p className="m-0">{meal.amount}</p>
                      <OverlayTrigger
                        trigger="click"
                        placement="top"
                        onToggle={(e) => setAmountToPay(1)}
                        overlay={
                          <Tooltip placement="bottom">
                            Кол-во на аукционе / кол-во для оплаты
                          </Tooltip>
                        }
                      >
                        <p className="m-0">
                          ({meal.onChangeAmount}/{meal.amountToPay})
                        </p>
                      </OverlayTrigger>
                    </td>
                  )}
                  <td>{meal.amount * meal.price}</td>
                </tr>
              ))}
              <tr>
                <td className="text-end" colspan="3">
                  Итого:
                </td>
                <td>{form.total}</td>
              </tr>
            </tbody>
          </Table>
          {userContext.access.editOrderedOrders || form.status !== "ordered" ? (
            <Button
              variant="outline-primary"
              onClick={(e) => setIsMenuOpen(true)}
            >
              Добавить блюдо
            </Button>
          ) : (
            ""
          )}
          {totalCpfc ? (
            <div
              className="text-center text-lowercase text-muted m-3"
              style={{ fontSize: 13 }}
            >{`ккал: ${totalCpfc.Cc.toFixed(
              2
            )}  |  белков: ${totalCpfc.P.toFixed(
              2
            )}  |  жиров: ${totalCpfc.F.toFixed(
              2
            )}  |  углеводов: ${totalCpfc.C.toFixed(2)}`}</div>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {(form._id !== "") &
          (userContext.access.editOrderedOrders ||
            form.status !== "ordered") ? (
            <Button variant="danger" onClick={(e) => onDelete()}>
              Удалить
            </Button>
          ) : (
            ""
          )}

          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          {userContext.access.editOrderedOrders || form.status !== "ordered" ? (
            <Button
              variant="primary"
              onClick={(e) => onSubmit(e)}
              disabled={isLoading}
            >
              {isLoading ? "Сохраняем..." : "Сохранить"}
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <ExchangePopup
        meal={mealToExchange}
        setMeal={setMealToExchange}
        me={true}
        setNeedUpdate={setNeedUpdateLocal}
      />
      <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} onAdd={mealAdd} />
    </>
  );
};

export default AllOrderPopup;
