import React from "react";
import { Spinner } from "react-bootstrap";

const Preloader = () => {
  return (
    <div
      style={{
        background: "rgba(255,255,255, .8)",
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Spinner animation="border" variant="primary" />
      <h3 className="mt-3">Загрузка...</h3>
    </div>
  );
};

export default Preloader;
