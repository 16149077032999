import React, { useState, useEffect } from "react";
import { Table, Pagination, Form, Button, Accordion } from "react-bootstrap";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export default function ApplicationsTable() {
  const [applications, setApplications] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [users, setUsers] = useState([]);
  const [querryParams, setQuerryParams] = useState({
    p: 1,
    s: 10,
  });
  const [pagin, setPagin] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    if (totalDocs === undefined) return;
    const items = [];
    const maxPage = Math.ceil(totalDocs / querryParams.s);
    for (
      let number =
        maxPage > 6 ? (querryParams.p <= 1 ? 1 : querryParams.p - 1) : 1;
      number <= maxPage;
      number++
    ) {
      if (number > querryParams.p + 6) break;
      if ((number === querryParams.p + 6) & (maxPage > querryParams.p + 6)) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: number })}
          >
            ...
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item
            key={maxPage}
            active={maxPage === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: maxPage })}
          >
            {maxPage}
          </Pagination.Item>
        );
        continue;
      }

      items.push(
        <Pagination.Item
          key={number}
          active={number === querryParams.p}
          onClick={() => setQuerryParams({ ...querryParams, p: number })}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPagin(items);
  }, [querryParams, totalDocs]);

  useEffect(() => {
    api
      .getApplications(querryParams)
      .then(({ applications, totalDocs, users }) => {
        setApplications(applications);
        setTotalDocs(totalDocs);
        setUsers(users);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, [querryParams]);

  return (
    <>
      <h1>Заявки</h1>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="primary" onClick={() => navigator("new")}>
          Создать
        </Button>
      </div>
      <Accordion defaultActiveKey="0" className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>Дата для заказов с</Form.Label>
              <DatePicker
                selected={querryParams.dateFrom}
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(dateFrom) =>
                  setQuerryParams({ ...querryParams, dateFrom })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Дата для заказов </Form.Label>
              <DatePicker
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                selected={querryParams.dateTo}
                onChange={(dateTo) =>
                  setQuerryParams({ ...querryParams, dateTo })
                }
              />
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Кто заказывал</Form.Label>
              <Form.Select
                value={querryParams.f_owner}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_owner: e.target.value })
                }
              >
                <option value=""></option>
                {users.map((el) => (
                  <option value={el._id} key={el._id}>
                    {el.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-2">
              <Form.Label>Статус</Form.Label>
              <Form.Select
                value={querryParams.f_status}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_status: e.target.value })
                }
              >
                <option value=""></option>
                <option value="ordered">Подтверждена</option>
                <option value="notOrdered">Не подтверждена</option>
              </Form.Select>
            </Form.Group>
            <Button
              variant="secondary"
              onClick={() => setQuerryParams({ p: 1, s: 10, f_owner: "" })}
            >
              Очистить
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Для заказов с</th>
            <th>Для заказов по</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {applications
            ? applications.map((application) => (
                <tr
                  key={application._id}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigator(application._id)}
                >
                  <td>
                    {new Date(application.dateFrom).toLocaleDateString(
                      "ru-RU",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      }
                    )}
                  </td>
                  <td>
                    {new Date(application.dateTo).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })}
                  </td>
                  <td
                    className={
                      application.status === "ordered"
                        ? "text-success"
                        : "text-danger"
                    }
                  >
                    {application.status === "ordered"
                      ? "Подтверждена"
                      : "Не подтверждена"}
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </Table>

      {pagin ? (
        <>
          <div className="d-none justify-content-between d-md-flex" size="sm">
            <Pagination className="m-0">
              {totalDocs > 10 ? (
                <Pagination.Item
                  active={10 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 10, p: 1 })
                  }
                >
                  10
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 20 ? (
                <Pagination.Item
                  active={20 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 20, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 30 ? (
                <Pagination.Item
                  active={30 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 30, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 50 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 50, p: 1 })
                  }
                >
                  50
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 75 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 75, p: 1 })
                  }
                >
                  75
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 100 ? (
                <Pagination.Item
                  active={100 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 100, p: 1 })
                  }
                >
                  100
                </Pagination.Item>
              ) : (
                ""
              )}
            </Pagination>
            <Pagination className="m-0">
              <Pagination.First
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) => setQuerryParams({ ...querryParams, p: 1 })}
                disabled={querryParams.p <= 1}
              />
              <Pagination.Prev
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p - 1 })
                }
                disabled={querryParams.p <= 1}
              />
              {pagin}
              <Pagination.Next
                className={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                    ? "fw-bold"
                    : "fw-normal"
                }
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p + 1 })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
              <Pagination.Last
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: Math.ceil(totalDocs / querryParams.s),
                  })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
          <div className="d-flex justify-content-between d-md-none" size="sm">
            {totalDocs > 10 ? (
              <Form.Select
                onChange={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: 1,
                    s: e.target.value,
                  })
                }
                style={{ width: 100 }}
              >
                {totalDocs > 10 ? <option value="10">10</option> : ""}
                {totalDocs > 20 ? <option value="20">20</option> : ""}
                {totalDocs > 30 ? <option value="30">30</option> : ""}
                {totalDocs > 50 ? <option value="50">50</option> : ""}
                {totalDocs > 75 ? <option value="75">75</option> : ""}
                {totalDocs > 100 ? <option value="100">100</option> : ""}
              </Form.Select>
            ) : (
              ""
            )}

            <Pagination className="m-0">
              <Pagination.Prev
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: querryParams.p === 1 ? 1 : querryParams.p - 1,
                  })
                }
                disabled={querryParams.p === 1}
              />
              <Pagination.Next
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p:
                      querryParams.p === Math.ceil(totalDocs / querryParams.s)
                        ? Math.ceil(totalDocs / querryParams.s)
                        : querryParams.p + 1,
                  })
                }
                disabled={
                  querryParams.p === Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
