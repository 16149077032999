import React, { useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import smallLogo from "../media/small_logo.svg";
import { api } from "../utils/api";

const Fridge = () => {
  const [meals, setMeals] = useState([]);
  const [report, setReport] = useState([]);

  const navigator = useNavigate();

  useEffect(() => {
    Promise.all([api.getMeals({ p: 1, s: 10000 }), api.getFridge()])
      .then(([{ meals }, { report }]) => {
        setMeals(meals);
        setReport(report);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);
  return (
    <>
      <h1>Холодильник</h1>
      <div className="d-flex flex-wrap">
        {report.length > 0 ? (
          report.map((item) => (
            <Card
              className="d-flex flex-column justify-content-center p-2 m-2 align-items-center"
              style={{ width: 300 }}
            >
              <img
                src={
                  meals.find((el) => el.name === item.name).image
                    ? meals.find((el) => el.name === item.name).image
                    : smallLogo
                }
                alt={item.name}
                className="rounded float-start"
                style={{ width: 80 }}
              />
              <p className="m-0 text-center fw-bold">{item.name}</p>
              <p className="m-0 text-center">{item.count} шт.</p>
              <OverlayTrigger
                trigger="click"
                placement="auto"
                rootClose={true}
                overlay={
                  <Tooltip placement="auto">{item.owners.join(", ")}</Tooltip>
                }
              >
                <p className="text-center m-0 text-muted">Кто заказывал?</p>
              </OverlayTrigger>
            </Card>
          ))
        ) : (
          <p className="fs-3">Ничего нет...</p>
        )}
      </div>
    </>
  );
};

export default Fridge;
