class Api {
  constructor() {
    this._baseURL = "";
  }
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject(res);
  }

  _createUrl(params) {
    let querry = "?";
    const keys = Object.keys(params);
    keys.forEach((key) => {
      if (
        (params[key] !== undefined) &
        (params[key] !== "") &
        (params[key] !== null)
      ) {
        querry += `${key}=${params[key]}&`;
      }
    });
    return querry;
  }

  signIn(data) {
    return fetch(this._baseURL + "/api/signin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  signUp(data) {
    return fetch(this._baseURL + "/api/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getMeals(params) {
    return fetch(this._baseURL + "/api/meals" + this._createUrl(params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getMeal(id) {
    return fetch(this._baseURL + "/api/meals/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getRandomMeal() {
    return fetch(this._baseURL + "/api/meals/random", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  parseMeals() {
    return fetch(this._baseURL + "/api/meals/crowl_meals", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postMeal(data) {
    return fetch(this._baseURL + "/api/meals/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editMeal(data) {
    return fetch(this._baseURL + "/api/meals/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }
  
  deleteMeal(mealId){
    return fetch(this._baseURL + "/api/meals/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({mealId}),
    }).then((res) => this._checkResponse(res));
  }

  getMyOrders(params) {
    return fetch(this._baseURL + "/api/my_orders" + this._createUrl(params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getMyOrder(id) {
    return fetch(this._baseURL + "/api/my_orders/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postMyOrder(data) {
    return fetch(this._baseURL + "/api/my_orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editMyOrder(data) {
    return fetch(this._baseURL + "/api/my_orders/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteMealFromMyOrder(orderId) {
    return fetch(this._baseURL + "/api/my_orders/meal", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId }),
    }).then((res) => this._checkResponse(res));
  }

  deleteMyOrder(orderId) {
    return fetch(this._baseURL + "/api/my_orders/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId }),
    }).then((res) => this._checkResponse(res));
  }

  payForMyMeal(data) {
    return fetch(this._baseURL + "/api/my_orders/pay_for_meal", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getOrders(params) {
    return fetch(this._baseURL + "/api/all_orders" + this._createUrl(params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getOrder(id) {
    return fetch(this._baseURL + "/api/all_orders/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postOrder(data) {
    return fetch(this._baseURL + "/api/all_orders/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editOrder(data) {
    return fetch(this._baseURL + "/api/all_orders/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteOrder(orderId) {
    return fetch(this._baseURL + "/api/all_orders/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ orderId }),
    }).then((res) => this._checkResponse(res));
  }

  payForMeal(data) {
    return fetch(this._baseURL + "/api/all_orders/pay_for_meal", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getUser(id) {
    return fetch(this._baseURL + "/api/users/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  postUser(data) {
    return fetch(this._baseURL + "/api/users/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editUser(data) {
    return fetch(this._baseURL + "/api/users/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteUser(userId) {
    return fetch(this._baseURL + "/api/users/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId }),
    }).then((res) => this._checkResponse(res));
  }

  getUsers(params) {
    return fetch(this._baseURL + "/api/users" + this._createUrl(params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getApplications(params) {
    return fetch(
      this._baseURL + "/api/applications" + this._createUrl(params),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => this._checkResponse(res));
  }

  getApplication(id) {
    return fetch(this._baseURL + "/api/applications/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  exportApplication(id) {
    return fetch(this._baseURL + "/api/applications/export_to_excel", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({applicationId: id}),
    }).then((res) => this._checkResponse(res));
  }

  postApplication(data) {
    return fetch(this._baseURL + "/api/applications/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  confirmApplication(data) {
    return fetch(this._baseURL + "/api/applications/confirm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  updateApplication(data) {
    return fetch(this._baseURL + "/api/applications/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  requestApplication(data) {
    return fetch(this._baseURL + "/api/applications/pre_create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteApplication(applicationId) {
    return fetch(this._baseURL + "/api/applications/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ applicationId }),
    }).then((res) => this._checkResponse(res));
  }

  getFridge() {
    return fetch(this._baseURL + "/api/reports/fridge", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getReport(params) {
    return fetch(
      this._baseURL + "/api/reports/report" + this._createUrl(params),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => this._checkResponse(res));
  }

  getReportExcel(params) {
    return fetch(
      this._baseURL + "/api/reports/report_excel" + this._createUrl(params),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => this._checkResponse(res));
  }

  getMe() {
    return fetch(this._baseURL + "/api/user/me", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getOurTeam() {
    return fetch(this._baseURL + "/api/teams/our", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  editOurTeam(data) {
    return fetch(this._baseURL + "/api/teams/our", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getTeams(params) {
    return fetch(this._baseURL + "/api/teams/" + this._createUrl(params), {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getTeam(id) {
    return fetch(this._baseURL + "/api/teams/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  editTeam(data) {
    return fetch(this._baseURL + "/api/teams/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  userLogOut() {
    return fetch(this._baseURL + "/api/user/logout", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  patchMe(data) {
    return fetch(this._baseURL + "/api/user/me", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  uploadAvatar({ file, filename }) {
    return fetch(this._baseURL + "/api/user/me_avatar", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ file, filename }),
    }).then((res) => this._checkResponse(res));
  }

  getExchanges() {
    return fetch(this._baseURL + "/api/exchanges/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getExchange(id) {
    return fetch(this._baseURL + "/api/exchanges/" + id, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createExchange(data) {
    return fetch(this._baseURL + "/api/exchanges/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  confirmeExchange(data) {
    return fetch(this._baseURL + "/api/exchanges/confirm", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  denyExchange(data) {
    return fetch(this._baseURL + "/api/exchanges/deny", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  copyOrdersFromPrevWeek(data) {
    return fetch(this._baseURL + "/api/my_orders/copy_order_from_prev_week", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  sendMealty(data) {
    return fetch(this._baseURL + "/api/mealty/send", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getLimits() {
    return fetch(this._baseURL + "/api/limits/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createLimit(data) {
    return fetch(this._baseURL + "/api/limits/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  updateLimit(data) {
    return fetch(this._baseURL + "/api/limits/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  getNextDatePeriod() {
    return fetch(this._baseURL + "/api/limits/get_next_date_period", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getReviewsByProduct(product) {
    return fetch(this._baseURL + "/api/review?product=" + product, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  createReview(data) {
    return fetch(this._baseURL + "/api/review/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  editReview(data) {
    return fetch(this._baseURL + "/api/review/", {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  deleteReview(reviewId) {
    return fetch(this._baseURL + "/api/review/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reviewId }),
    }).then((res) => this._checkResponse(res));
  }

  addToFavorites(mealId) {
    return fetch(this._baseURL + "/api/favorites/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mealId }),
    }).then((res) => this._checkResponse(res));
  }

  deleteFromFavorites(mealId) {
    return fetch(this._baseURL + "/api/favorites/", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mealId }),
    }).then((res) => this._checkResponse(res));
  }

  importFileToMeals(data) {
    return fetch(this._baseURL + "/api/meals/upload_import", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  saveFileToMeals(data) {
    return fetch(this._baseURL + "/api/meals/save_import", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }).then((res) => this._checkResponse(res));
  }

  rootLogin(id) {
    return fetch(this._baseURL + "/api/users/root_signin", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({userId: id}),
    }).then((res) => this._checkResponse(res));
  }

  rootLogout(id) {
    return fetch(this._baseURL + "/api/users/root_logout", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }

  getRecomendationForMe() {
    return fetch(this._baseURL + "/api/recommendation/", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => this._checkResponse(res));
  }
}

const api = new Api();

export { api };
