import React, { useState, useEffect } from "react";
import { Modal, Button, Card, Form, Nav } from "react-bootstrap";
import { api } from "../utils/api";
import MealCard from "./MealCard";
import MealGrid from "./MealGrid";

const Menu = ({ isOpen, onAdd, setIsOpen }) => {
  const [meals, setMeals] = useState([]);
  const [finder, setFinder] = useState({
    f_name: "",
    f_group: "",
  });
  const [page, setPage] = useState(1);
  const [totalDocs, setTotalDocs] = useState(0);
  const [meal, setMeal] = useState(null);
  const [mealsGroups, setMealsGroups] = useState(null);
  const [recommendation, setRecommendation] = useState(null);

  useEffect(() => {
    api.getRecomendationForMe().then((meals) => {
      setRecommendation(meals);
    });
  }, []);

  useEffect(() => {
    if ((finder.f_name !== "") || (finder.f_group !== "")) return;
    api
      .getMeals({ p: 1, s: 10, f_show_favorites: true, f_activity: true })
      .then(({ meals, totalDocs, groups }) => {
        setMeals(meals);
        setTotalDocs(totalDocs);
        setMealsGroups(groups);
      });
  }, [finder]);

  useEffect(() => {
    setFinder({
      f_name: "",
      f_group: "",
    });
  }, [isOpen]);

  const onMore = () => {
    if ((finder.f_name !== "") & (finder.f_group !== "")) {
      setFinder({
        f_name: "",
        f_group: "",
      });
    }
    setPage(page + 1);
    api
      .getMeals({
        p: page + 1,
        s: 10,
        f_show_favorites: true,
        f_activity: true,
      })
      .then((res) => {
        setMeals([...meals, ...res.meals]);
      });
  };

  useEffect(() => {
    if ((finder.f_name === "") & (finder.f_group === "")) return;
    setPage(1);
    api
      .getMeals({
        ...finder,
        f_show_favorites: true,
        s: 10000,
        f_activity: true,
      })
      .then(({ meals }) => {
        setMeals(meals);
      });
  }, [finder]);

  const addToFavorite = (meal) => {
    api.addToFavorites(meal).then(() => {
      setMeals(
        meals.map((ml) => {
          if (ml._id === meal) {
            return {
              ...ml,
              isFavorite: true,
            };
          } else {
            return ml;
          }
        })
      );
    });
  };

  const deleteFromFavorite = (meal) => {
    api.deleteFromFavorites(meal).then(() => {
      setMeals(
        meals.map((ml) => {
          if (ml._id === meal) {
            return {
              ...ml,
              isFavorite: false,
            };
          } else {
            return ml;
          }
        })
      );
    });
  };

  return (
    <>
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        size="lg"
        fullscreen="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Меню</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-3">
          <Form.Control
            type="text"
            placeholder="Введите название блюда"
            value={finder.f_name}
            onChange={(e) =>
              setFinder({
                ...finder,
                f_name: e.target.value,
              })
            }
            name="name"
          />
          {mealsGroups ? (
            <Nav
              activeKey={finder.f_group}
              onSelect={(f_group) => setFinder({ f_name: "", f_group })}
              className="justify-content-center"
            >
              <Nav.Item>
                <Nav.Link eventKey={""}>Все</Nav.Link>
              </Nav.Item>
              {mealsGroups.map((el) => (
                <Nav.Item>
                  <Nav.Link eventKey={el}>{el}</Nav.Link>
                </Nav.Item>
              ))}
              {finder.f_group !== "favorite" ? (
                <Nav.Item
                  onClick={() => {
                    api
                      .getRandomMeal()
                      .then(({ random }) =>
                        setFinder({ f_group: "", f_name: random.name })
                      );
                  }}
                >
                  <Nav.Link>Мне повезет!</Nav.Link>
                </Nav.Item>
              ) : (
                ""
              )}
              <Nav.Item>
                <Nav.Link eventKey="favorite">
                  <i
                    className="bi bi-bookmark-star-fill fs-2"
                    style={{ position: "relative", top: -3 }}
                  />
                </Nav.Link>
              </Nav.Item>
            </Nav>
          ) : (
            ""
          )}

          <div className="d-flex flex-wrap mt-4 justify-content-center">
            {meals
              ? meals.map((meal) => (
                  <MealGrid
                    deleteFromFavorite={deleteFromFavorite}
                    addToFavorite={addToFavorite}
                    meal={meal}
                    setMeal={setMeal}
                    onAdd={onAdd}
                    key={meal._id}
                  />
                ))
              : ""}
          </div>
          {finder.f_name !== "" ||
          finder.f_group !== "" ||
          totalDocs === meals.length ? (
            ""
          ) : (
            <div className="d-flex justify-content-center mt-2">
              <Button onClick={() => onMore()} variant="outline-secondary">
                Показать еще
              </Button>
            </div>
          )}
          {finder.f_group === "favorite" ? (
            recommendation ? (
              <>
                <h3 className="text-center mt-3">Возможно Вам понравиться</h3>
                <div className="d-flex flex-wrap mt-4 justify-content-center">
                  {recommendation.map((meal) => (
                    <MealGrid
                      deleteFromFavorite={deleteFromFavorite}
                      addToFavorite={addToFavorite}
                      meal={meal}
                      setMeal={setMeal}
                      onAdd={onAdd}
                      key={meal._id}
                    />
                  ))}
                </div>
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => setIsOpen(false)}>
            Закрыть
          </Button>
        </Modal.Footer>
      </Modal>
      <MealCard meal={meal} setMeal={setMeal} />
    </>
  );
};

export default Menu;
