import React, { useState, useEffect } from "react";
import { Card, Modal, Button, Form } from "react-bootstrap";
import { api } from "../utils/api";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import { useNavigate } from "react-router-dom";
import smallLogo from "../media/small_logo.svg";
registerLocale("ru", ru);

const Exchanges = () => {
  const [data, setData] = useState(null);
  const [users, setUsers] = useState([]);
  const [meals, setMeals] = useState(null);
  const [dateWhen, setDateWhen] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [exhangeToAccept, setExchangeToAccept] = useState(null);

  const navigator = useNavigate();

  useEffect(() => {
    Promise.all([api.getExchanges(), api.getMeals({ p: 1, s: 100000 })])
      .then(([exhanges, meals]) => {
        setData(exhanges);
        setUsers(exhanges.users);
        setMeals(meals.meals);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);

  const closePopup = () => {
    setExchangeToAccept(null);
    setDateWhen(null);
  };

  const acceptExchange = () => {
    api
      .confirmeExchange({ exchangeId: exhangeToAccept, date: dateWhen })
      .then(() => {
        closePopup();
        api
          .getExchanges()
          .then((exhanges) => {
            setData(exhanges);
            setUsers(exhanges.users);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const denyExchange = (id) => {
    api
      .denyExchange({ exchangeId: id })
      .then(() => {
        closePopup();
        api
          .getExchanges()
          .then((exhanges) => {
            setData(exhanges);
            setUsers(exhanges.users);
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <h1>Предлагают</h1>
      {data ? (
        <>
          <h2 className="mt-3">Всем</h2>
          {data.exchangesForAll.length > 0 ? (
            <div className="d-flex flex-wrap">
              {data.exchangesForAll.map((meal) => (
                <Card style={{ width: 300 }} className="m-2">
                  <Card.Header>{meal.meal.name}</Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center">
                    {meals ? (
                      meals.find((el) => el._id === meal.meal.mealId) &&
                      meals.find((el) => el._id === meal.meal.mealId).image ? (
                        <img
                          src={
                            meals.find((el) => el._id === meal.meal.mealId)
                              .image
                          }
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      ) : (
                        <img
                          src={smallLogo}
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                    <p className="m-0 mt-2">
                      От{" "}
                      <span>
                        {users
                          ? users.find((el) => el._id === meal.userFrom).name
                          : meal.userFrom}
                      </span>
                    </p>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between align-items-center">
                    {meal.meal.amount} шт.
                    <Button
                      variant="outline-success"
                      onClick={() => setExchangeToAccept(meal._id)}
                    >
                      Принять
                    </Button>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          ) : (
            <p>Ничего нет...</p>
          )}
          <h2 className="mt-2">Лично Вам</h2>
          {data.exchangesForU.length > 0 ? (
            <div className="d-flex flex-wrap">
              {data.exchangesForU.map((meal) => (
                <Card style={{ width: 300 }} className="m-2">
                  <Card.Header>{meal.meal.name}</Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center">
                    {meals ? (
                      meals.find((el) => el._id === meal.meal.mealId) &&
                      meals.find((el) => el._id === meal.meal.mealId).image ? (
                        <img
                          src={
                            meals.find((el) => el._id === meal.meal.mealId)
                              .image
                          }
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      ) : (
                        <img
                          src={smallLogo}
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                    <p className="m-0 mt-2">
                      От{" "}
                      <span>
                        {users
                          ? users.find((el) => el._id === meal.userFrom).name
                          : meal.userFrom}
                      </span>
                    </p>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between align-items-center">
                    {meal.meal.amount} шт.
                    <Button
                      variant="outline-danger"
                      className="me-3"
                      onClick={() => denyExchange(meal._id)}
                    >
                      Отклонить
                    </Button>
                    <Button
                      variant="outline-success"
                      onClick={() => setExchangeToAccept(meal._id)}
                    >
                      Принять
                    </Button>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          ) : (
            <p>Ничего нет...</p>
          )}
          <h2 className="mt-2">Мои предложения</h2>
          {data.mine.length > 0 ? (
            <div className="d-flex flex-wrap">
              {data.mine.map((meal) => (
                <Card style={{ width: 300 }} className="m-2">
                  <Card.Header>{meal.meal.name}</Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center">
                    {meals ? (
                      meals.find((el) => el._id === meal.meal.mealId) &&
                      meals.find((el) => el._id === meal.meal.mealId).image ? (
                        <img
                          src={
                            meals.find((el) => el._id === meal.meal.mealId)
                              .image
                          }
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      ) : (
                        <img
                          src={smallLogo}
                          alt={meal.meal.name}
                          className="rounded float-start"
                          style={{ width: 80 }}
                        />
                      )
                    ) : (
                      ""
                    )}
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between align-items-center">
                    {meal.meal.amount} шт.
                    <Button
                      variant="outline-danger"
                      onClick={() => denyExchange(meal._id)}
                    >
                      Оставить себе
                    </Button>
                  </Card.Footer>
                </Card>
              ))}
            </div>
          ) : (
            <p>Ничего нет...</p>
          )}
        </>
      ) : (
        ""
      )}
      <Modal show={exhangeToAccept ? true : false} onHide={() => closePopup()}>
        <Modal.Header closeButton>
          <Modal.Title>Принять блюдо</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Когда будем есть?</Form.Label>
              <DatePicker
                selected={dateWhen}
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(e) => setDateWhen(e)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            onClick={(e) => acceptExchange()}
            disabled={isLoading}
          >
            {isLoading ? "Идем договариваться..." : "Принять"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Exchanges;
