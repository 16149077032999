import React, { useState, useEffect } from "react";
import {
  Table,
  Pagination,
  Form,
  Button,
  Modal,
  Accordion,
} from "react-bootstrap";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";

export default function TeamsTable({teamId}) {
  const [teams, setTeams] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [querryParams, setQuerryParams] = useState({
    p: 1,
    s: 10,
  });
  const [pagin, setPagin] = useState(null);
  const navigator = useNavigate();

  useEffect(() => {
    if (totalDocs === undefined) return;
    const items = [];
    const maxPage = Math.ceil(totalDocs / querryParams.s);
    for (
      let number =
        maxPage > 6 ? (querryParams.p <= 1 ? 1 : querryParams.p - 1) : 1;
      number <= maxPage;
      number++
    ) {
      if (number > querryParams.p + 6) break;
      if ((number === querryParams.p + 6) & (maxPage > querryParams.p + 6)) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: number })}
          >
            ...
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item
            key={maxPage}
            active={maxPage === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: maxPage })}
          >
            {maxPage}
          </Pagination.Item>
        );
        continue;
      }

      items.push(
        <Pagination.Item
          key={number}
          active={number === querryParams.p}
          onClick={() => setQuerryParams({ ...querryParams, p: number })}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPagin(items);
  }, [totalDocs, querryParams]);

  useEffect(() => {
    api
      .getTeams(querryParams)
      .then(({ teams, totalDocs }) => {
        setTeams(teams);
        setTotalDocs(totalDocs);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, [querryParams]);

  return (
    <>
      <h1>Команды</h1>
      <Accordion defaultActiveKey="0" className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>Название</Form.Label>
              <Form.Control
                type="text"
                placeholder="Имя"
                value={querryParams.f_name}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_name: e.target.value })
                }
                name="f_name"
              />
            </Form.Group>
            <Form.Check
              type="switch"
              className="mt-4 mb-4"
              label=" Активность"
              name="f_activity"
              checked={querryParams.f_activity === true ? "on" : ""}
              onChange={(e) =>
                setQuerryParams({
                  ...querryParams,
                  f_activity: !querryParams.f_activity,
                })
              }
            />
            <Button
              variant="secondary"
              onClick={() => setQuerryParams({ p: 1, s: 10, f_name: "", f_activity: "" })}
            >
              Очистить
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Название</th>
            <th>Последняя активность</th>
          </tr>
        </thead>
        <tbody>
          {teams
            ? teams.map((user) => (
                <tr
                  key={user._id}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigator(user._id)}
                >
                  <td>{user.name}</td>
                  <td>
                    {new Date(user.lastActivity).toLocaleDateString("ru-RU", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </Table>

      {pagin ? (
        <>
          <div className="d-none justify-content-between d-md-flex" size="sm">
            <Pagination className="m-0">
              {totalDocs > 10 ? (
                <Pagination.Item
                  active={10 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 10, p: 1 })
                  }
                >
                  10
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 20 ? (
                <Pagination.Item
                  active={20 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 20, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 30 ? (
                <Pagination.Item
                  active={30 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 30, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 50 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 50, p: 1 })
                  }
                >
                  50
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 75 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 75, p: 1 })
                  }
                >
                  75
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 100 ? (
                <Pagination.Item
                  active={100 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 100, p: 1 })
                  }
                >
                  100
                </Pagination.Item>
              ) : (
                ""
              )}
            </Pagination>
            <Pagination className="m-0">
              <Pagination.First
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) => setQuerryParams({ ...querryParams, p: 1 })}
                disabled={querryParams.p <= 1}
              />
              <Pagination.Prev
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p - 1 })
                }
                disabled={querryParams.p <= 1}
              />
              {pagin}
              <Pagination.Next
                className={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                    ? "fw-bold"
                    : "fw-normal"
                }
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p + 1 })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
              <Pagination.Last
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: Math.ceil(totalDocs / querryParams.s),
                  })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
          <div className="d-flex justify-content-between d-md-none" size="sm">
            {totalDocs > 10 ? (
              <Form.Select
                onChange={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: 1,
                    s: e.target.value,
                  })
                }
                style={{ width: 100 }}
              >
                {totalDocs > 10 ? <option value="10">10</option> : ""}
                {totalDocs > 20 ? <option value="20">20</option> : ""}
                {totalDocs > 30 ? <option value="30">30</option> : ""}
                {totalDocs > 50 ? <option value="50">50</option> : ""}
                {totalDocs > 75 ? <option value="75">75</option> : ""}
                {totalDocs > 100 ? <option value="100">100</option> : ""}
              </Form.Select>
            ) : (
              ""
            )}

            <Pagination className="m-0">
              <Pagination.Prev
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: querryParams.p === 1 ? 1 : querryParams.p - 1,
                  })
                }
                disabled={querryParams.p === 1}
              />
              <Pagination.Next
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p:
                      querryParams.p === Math.ceil(totalDocs / querryParams.s)
                        ? Math.ceil(totalDocs / querryParams.s)
                        : querryParams.p + 1,
                  })
                }
                disabled={
                  querryParams.p === Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
