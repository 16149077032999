import React from "react";
import { Form, Button } from "react-bootstrap";

export default function SignUp({ signUp, form, onChange, onChangeCheck, isLoading }) {
  return (
    <Form onSubmit={signUp} className="card p-2">
      <h3>Данные команды</h3>
      <Form.Group className="mb-3">
        <Form.Label>Название команды</Form.Label>
        <Form.Control
          type="name"
          placeholder="Введите название"
          value={form.teamName}
          onChange={onChange}
          name="teamName"
        />
      </Form.Group>
      <h4>Включить меню</h4>
      <Form.Check
        type="switch"
        label=" Милти"
        name="mealtyMenu"
        checked={form.mealtyMenu === true ? "on" : ""}
        onChange={onChangeCheck}
      />
      <h4>Включить выгрузку заказа</h4>
      <Form.Check
        type="switch"
        label=" в Милти"
        name="mealtyExport"
        checked={form.mealtyExport === true ? "on" : ""}
        onChange={onChangeCheck}
      />
      <h3 className="mt-2">Данные администратора</h3>
      <Form.Group className="mb-3">
        <Form.Label>Имя</Form.Label>
        <Form.Control
          type="name"
          placeholder="Введите Имя"
          value={form.name}
          onChange={onChange}
          name="name"
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Введите Email"
          value={form.email}
          onChange={onChange}
          name="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Пароль</Form.Label>
        <Form.Control
          type="password"
          placeholder="Введите пароль"
          value={form.password}
          onChange={onChange}
          name="password"
          autoComplete="new-password"
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={isLoading}>
        Зарегистрироваться
      </Button>
    </Form>
  );
}
