import React, { useState, useEffect, useContext } from "react";
import {
  Table,
  Pagination,
  Form,
  Button,
  Modal,
  Accordion,
} from "react-bootstrap";
import { api } from "../../utils/api";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../utils/UserContext";
import { TeamContext } from "../../utils/TeamContext";
import ImportMealsPopup from "./ImportMealsPopup";
import smallLogo from "../../media/small_logo.svg";

export default function MealsTable() {
  const [meals, setMeals] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [querryParams, setQuerryParams] = useState({
    p: 1,
    s: 10,
  });
  const [pagin, setPagin] = useState(null);
  const [isParsing, setIsParsing] = useState(false);
  const [isToParsing, setIsToParsing] = useState(false);
  const [mealsGroups, setMealsGroups] = useState(null);
  const [filePopup, setFilePopup] = useState(false);
  const userContext = useContext(UserContext);
  const teamContext = useContext(TeamContext);
  const navigator = useNavigate();

  useEffect(() => {
    if (totalDocs === undefined) return;
    const items = [];
    const maxPage = Math.ceil(totalDocs / querryParams.s);
    for (
      let number =
        maxPage > 6 ? (querryParams.p <= 1 ? 1 : querryParams.p - 1) : 1;
      number <= maxPage;
      number++
    ) {
      if (number > querryParams.p + 6) break;
      if ((number === querryParams.p + 6) & (maxPage > querryParams.p + 6)) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: number })}
          >
            ...
          </Pagination.Item>
        );
        items.push(
          <Pagination.Item
            key={maxPage}
            active={maxPage === querryParams.p}
            onClick={() => setQuerryParams({ ...querryParams, p: maxPage })}
          >
            {maxPage}
          </Pagination.Item>
        );
        continue;
      }

      items.push(
        <Pagination.Item
          key={number}
          active={number === querryParams.p}
          onClick={() => setQuerryParams({ ...querryParams, p: number })}
        >
          {number}
        </Pagination.Item>
      );
    }
    setPagin(items);
  }, [totalDocs, querryParams]);

  useEffect(() => {
    api
      .getMeals(querryParams)
      .then(({ meals, totalDocs, groups }) => {
        setMeals(meals);
        setTotalDocs(totalDocs);
        setMealsGroups(groups);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, [querryParams]);

  const onParse = () => {
    setIsParsing(true);
    api
      .parseMeals()
      .then(() => {
        setQuerryParams({ p: 1, s: 10 });
        setIsToParsing(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsParsing(false));
  };

  return (
    <>
      <h1>Блюда</h1>
      <div className="d-flex justify-content-end mb-2">
        <Button variant="primary me-3" onClick={() => setFilePopup(true)}>
          Импорт из excel
        </Button>
        {userContext.isRoot ? (
          <Button variant="primary me-3" onClick={() => setIsToParsing(true)}>
            Получить из Милти
          </Button>
        ) : (
          ""
        )}
        <Button variant="primary" onClick={() => navigator("new")}>
          Создать
        </Button>
      </div>
      <Accordion defaultActiveKey="0" className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>Название</Form.Label>
              <Form.Control
                type="text"
                placeholder="Название"
                value={querryParams.f_name}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_name: e.target.value })
                }
                name="f_name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Цена</Form.Label>
              <Form.Control
                type="number"
                placeholder="Цена"
                value={querryParams.f_price}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_price: e.target.value })
                }
                name="f_price"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Меню</Form.Label>
              <Form.Select
                value={querryParams.f_menutype}
                onChange={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    f_menutype: e.target.value,
                  })
                }
              >
                <option value="all">Все</option>
                <option value="personal">Команды</option>
                {teamContext.menuInclude.mealty ? (
                  <option value="mealty">Милти</option>
                ) : (
                  ""
                )}
              </Form.Select>
            </Form.Group>
            <Form.Check
              type="switch"
              className="mt-4 mb-4"
              label=" Активность"
              name="f_activity"
              checked={querryParams.f_activity === true ? "on" : ""}
              onChange={(e) =>
                setQuerryParams({
                  ...querryParams,
                  f_activity: !querryParams.f_activity,
                })
              }
            />
            <Form.Group className="mb-3">
              <Form.Label>Группа</Form.Label>
              <Form.Control
                type="text"
                placeholder="Группа"
                value={querryParams.f_group}
                onChange={(e) =>
                  setQuerryParams({ ...querryParams, f_group: e.target.value })
                }
                name="f_group"
              />

              <div className="d-flex flex-wrap mt-2" style={{ gap: 10 }}>
                {mealsGroups
                  ? mealsGroups.map((group) => (
                      <Button
                        variant="outline-secondary"
                        onClick={() =>
                          setQuerryParams({ ...querryParams, f_group: group })
                        }
                      >
                        {group}
                      </Button>
                    ))
                  : ""}
                <Button
                  variant="secondary"
                  onClick={() =>
                    setQuerryParams({ ...querryParams, f_group: "" })
                  }
                >
                  Очистить
                </Button>
              </div>
            </Form.Group>
            <Button
              variant="secondary"
              onClick={() =>
                setQuerryParams({
                  p: 1,
                  s: 10,
                  f_name: "",
                  f_price: "",
                  f_menutype: "",
                  f_activity: "",
                  f_group: "",
                })
              }
            >
              Очистить
            </Button>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Название</th>
            <th>Цена</th>
            <th>Изображение</th>
          </tr>
        </thead>
        <tbody>
          {meals
            ? meals.map((meal) => (
                <tr
                  key={meal._id}
                  style={{ cursor: "pointer" }}
                  onClick={() => navigator(meal._id)}
                >
                  <td>
                    <p className="m-0">{meal.name}</p>
                    <p className="m-0 fs-5">{meal.subName}</p>
                  </td>
                  <td>{meal.price}</td>
                  <td>
                    <img
                      src={meal.image ? meal.image : smallLogo}
                      alt={meal.name}
                      className="rounded float-start"
                      style={{ width: 50 }}
                    />
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </Table>

      {pagin ? (
        <>
          <div className="d-none justify-content-between d-md-flex" size="sm">
            <Pagination className="m-0">
              {totalDocs > 10 ? (
                <Pagination.Item
                  active={10 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 10, p: 1 })
                  }
                >
                  10
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 20 ? (
                <Pagination.Item
                  active={20 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 20, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 30 ? (
                <Pagination.Item
                  active={30 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 30, p: 1 })
                  }
                >
                  20
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 50 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 50, p: 1 })
                  }
                >
                  50
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 75 ? (
                <Pagination.Item
                  active={50 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 75, p: 1 })
                  }
                >
                  75
                </Pagination.Item>
              ) : (
                ""
              )}
              {totalDocs > 100 ? (
                <Pagination.Item
                  active={100 === querryParams.s}
                  onClick={() =>
                    setQuerryParams({ ...querryParams, s: 100, p: 1 })
                  }
                >
                  100
                </Pagination.Item>
              ) : (
                ""
              )}
            </Pagination>
            <Pagination className="m-0">
              <Pagination.First
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) => setQuerryParams({ ...querryParams, p: 1 })}
                disabled={querryParams.p <= 1}
              />
              <Pagination.Prev
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p - 1 })
                }
                disabled={querryParams.p <= 1}
              />
              {pagin}
              <Pagination.Next
                className={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                    ? "fw-bold"
                    : "fw-normal"
                }
                onClick={(e) =>
                  setQuerryParams({ ...querryParams, p: querryParams.p + 1 })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
              <Pagination.Last
                className={querryParams.p <= 1 ? "fw-bold" : "fw-normal"}
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: Math.ceil(totalDocs / querryParams.s),
                  })
                }
                disabled={
                  querryParams.p >= Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
          <div className="d-flex justify-content-between d-md-none" size="sm">
            {totalDocs > 10 ? (
              <Form.Select
                onChange={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: 1,
                    s: e.target.value,
                  })
                }
                style={{ width: 100 }}
              >
                {totalDocs > 10 ? <option value="10">10</option> : ""}
                {totalDocs > 20 ? <option value="20">20</option> : ""}
                {totalDocs > 30 ? <option value="30">30</option> : ""}
                {totalDocs > 50 ? <option value="50">50</option> : ""}
                {totalDocs > 75 ? <option value="75">75</option> : ""}
                {totalDocs > 100 ? <option value="100">100</option> : ""}
              </Form.Select>
            ) : (
              ""
            )}
            <Pagination className="m-0">
              <Pagination.Prev
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p: querryParams.p === 1 ? 1 : querryParams.p - 1,
                  })
                }
                disabled={querryParams.p === 1}
              />
              <Pagination.Next
                onClick={(e) =>
                  setQuerryParams({
                    ...querryParams,
                    p:
                      querryParams.p === Math.ceil(totalDocs / querryParams.s)
                        ? Math.ceil(totalDocs / querryParams.s)
                        : querryParams.p + 1,
                  })
                }
                disabled={
                  querryParams.p === Math.ceil(totalDocs / querryParams.s)
                }
              />
            </Pagination>
          </div>
        </>
      ) : (
        ""
      )}
      <Modal show={isToParsing} onHide={() => setIsToParsing(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isParsing
              ? "Получаем блюда с сайта Милти"
              : "Вы уверены? Все блюда будут заменены!"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          {isParsing ? (
            ""
          ) : (
            <>
              <Button
                variant="outline-secondary"
                onClick={() => setIsToParsing(false)}
              >
                Закрыть
              </Button>
              <Button variant="primary" onClick={(e) => onParse(e)}>
                Парсить!
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <ImportMealsPopup setFilePopup={setFilePopup} filePopup={filePopup} />
    </>
  );
}
