import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Accordion } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import UsersTable from "../Users/UsersTable";

export default function TeamsForm() {
  const [form, setForm] = useState({
    isActive: true,
    name: "",
    menuInclude: {
      mealty: false,
    },
    useExport: {
      mealty: false,
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigator = useNavigate();
  const { id } = useParams();

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeSwitchExport = (e) => {
    setForm({
      ...form,
      useExport: {
        ...form.useExport,
        [e.target.name]: !form.useExport[e.target.name],
      },
    });
  };

  const onChangeSwitchMenu = (e) => {
    setForm({
      ...form,
      menuInclude: {
        ...form.menuInclude,
        [e.target.name]: !form.menuInclude[e.target.name],
      },
    });
  };

  const closePopup = () => {
    setForm({
      isActive: true,
      name: "",
      menuInclude: {
        mealty: false,
      },
      useExport: {
        mealty: false,
      },
    });
    navigator("/teams");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { name, menuInclude, useExport, isActive } = form;
    api
      .editTeam({
        name,
        menuInclude,
        useExport,
        isActive,
      })
      .then(() => closePopup())
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const onDelete = () => {
    setIsLoading(true);
    api
      .deleteTeam(form._id)
      .then(() => closePopup())
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id === "new") return;
    api
      .getTeam(id)
      .then(({ team }) => setForm(team))
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);

  return (
    <>
      <Modal show={true} onHide={() => closePopup()} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{form.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="switch"
              className="mt-4 mb-4"
              label=" Активность"
              name="isActive"
              checked={form.isActive === true ? "on" : ""}
              onChange={(e) =>
                setForm({
                  ...form,
                  isActive: !form.isActive,
                })
              }
            />
            <Form.Group className="mb-3">
              <Form.Label>Название команды</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите название"
                value={form.name}
                onChange={onChange}
                name="name"
              />
            </Form.Group>
            <h3 className="mb-1 mt-3">Интеграция меню</h3>
            <Form.Check
              type="switch"
              className="mt-2 mb-2"
              label=" Милти"
              name="mealty"
              checked={form.menuInclude.mealty === true ? "on" : ""}
              onChange={(e) => onChangeSwitchMenu(e)}
            />
            <h3 className="mb-1 mt-3">Интеграция заявок</h3>
            <Form.Check
              type="switch"
              className="mt-2 mb-2"
              label=" Милти"
              name="mealty"
              checked={form.useExport.mealty === true ? "on" : ""}
              onChange={(e) => onChangeSwitchExport(e)}
            />
          </Form>
          <div className="mt-5">
            <UsersTable teamId={form._id} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={(e) => setIsDeleting(true)}>
            Удалить
          </Button>

          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            onClick={(e) => onSubmit(e)}
            disabled={isLoading}
          >
            {isLoading ? "Сохраняем..." : "Сохранить"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isDeleting} onHide={() => setIsDeleting(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Вы уверены?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setIsDeleting(false)}
          >
            Закрыть
          </Button>
          <Button variant="danger" onClick={(e) => onDelete(e)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
