function urlParser(querryString){
    let parsedUrl = querryString
    .replace(/^\?(\S)/, "$1")
    .replace(/&$/, "")
    .split("&")
    .map((el) => {
      return el.split("=");
    });
  const querryParams = {};

  parsedUrl.forEach(element => {
    querryParams[element[0]] = decodeURI(element[1]);
  });

  return querryParams;
}

export default urlParser;