import React, { useState, useEffect } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import { api } from "../../utils/api";
import toBase64 from "../../utils/toBase64";

export default function ImportMealsPopup({ filePopup, setFilePopup }) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [file, setFile] = useState(undefined);
  const [fileTimeStamp, setFileTimeStamp] = useState(null);
  const [table, setTable] = useState(null);
  const [settings, setSettings] = useState({
    type: "",
    conformity: null,
    columns: [],
  });
  const mealsFields = [
    ["name", "Название"],
    ["subName", "Подназвание"],
    ["isActive", "Активность"],
    ["price", "Цена"],
    ["weightUnit", "Ед. измерения"],
    ["weight", "Вес"],
    ["weightwWithUnit", "Вес + Ед. измерения"],
    ["description", "Описание"],
    ["proteins", "Белки"],
    ["fats", "Жиры"],
    ["carbohydrates", "Углеводы"],
    ["calories", "Калории"],
    ["products", "Состав"],
    ["group", "Группа"],
  ];

  const onFileUpload = async () => {
    setIsLoading(true);
    const fileInBase64 = await toBase64(file);
    api
      .importFileToMeals({ file: fileInBase64, filename: file.name })
      .then(({ fileTimeStamp, table }) => {
        setFileTimeStamp(fileTimeStamp);
        setTable(table);
        setSettings({ ...settings, columns: table[0].map(() => null) });
      })
      .catch((err) =>
        err.json().then((mes) => {
          setError(JSON.stringify(mes));
        })
      )
      .finally(() => setIsLoading(false));
  };

  const onImport = () => {
    setIsLoading(true);
    api
      .saveFileToMeals({ fileTimeStamp, ...settings })
      .then(({ fileTimeStamp, table }) => {
        setFilePopup(false);
      })
      .catch((err) =>
        err.json().then((mes) => {
          setError(JSON.stringify(mes));
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    setFileTimeStamp(null);
    setTable(null);
    setFile(undefined);
    setError(null);
    setIsLoading(false);
    setSettings({
      type: "",
      conformity: null,
      columns: [],
    });
  }, [filePopup]);

  return (
    <Modal
      show={filePopup}
      onHide={() => setFilePopup(false)}
      size="lg"
      // dialogClassName="modal-90w"
      // style={{ maxWidth: 1200, width: "90%", minWidth: 300 }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Импорт блюд</Modal.Title>
      </Modal.Header>
      {fileTimeStamp === null ? (
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Выберите файл</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setFile(e.target.files[0])}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </Form.Group>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Выберите тип импорта</Form.Label>
            <Form.Select
              value={settings.type}
              onChange={(e) =>
                setSettings({
                  ...settings,
                  type: e.target.value,
                })
              }
            >
              <option value=""></option>
              {Array.isArray(settings.conformity) ? (
                <option value="1">
                  Снять активность со всех блюд и проставить только тем, что в
                  импорте
                </option>
              ) : (
                ""
              )}
              <option value="2">Импортирорвать "как есть"</option>
              {!Array.isArray(settings.conformity) ? (
                <option value="3">
                  Импортировать с полной заменой меню команды (Осторожно !)
                </option>
              ) : (
                ""
              )}
            </Form.Select>
          </Form.Group>
          <Form.Check
            type="radio"
            label="Искать соответствия (При нахождении блюда, данные о нем будут обновлены)"
            id="conformity1"
            checked={Array.isArray(settings.conformity) ? "on" : ""}
            onChange={() => setSettings({ ...settings, conformity: [] })}
          />
          <Form.Check
            type="radio"
            label="Не искать соответствия (Создавать для всего импорта новые блюда)"
            id="conformity2"
            checked={!Array.isArray(settings.conformity) ? "on" : ""}
            onChange={() => setSettings({ ...settings, conformity: null })}
          />
          {Array.isArray(settings.conformity) ? (
            <>
              <p className="mb-1 mt-3 fw-bold">Выберите колонки соответствия</p>
              {mealsFields.map((el) => (
                <Form.Check
                  type="checkbox"
                  id={el[0]}
                  label={el[1]}
                  checked={settings.conformity.find(
                    (le) => ByteLengthQueuingStrategy === el[0]
                  )}
                  onChange={() => {
                    if (settings.conformity.find((le) => le === el[0])) {
                      setSettings({
                        ...settings,
                        conformity: settings.conformity.filter(
                          (le) => le !== el[0]
                        ),
                      });
                    } else {
                      setSettings({
                        ...settings,
                        conformity: [...settings.conformity, el[0]],
                      });
                    }
                  }}
                />
              ))}
            </>
          ) : (
            ""
          )}
          <div
            style={{ maxWidth: "100%", overflow: "scroll", maxHeight: "70vh" }}
          >
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  {table[0].map((cell, index) => (
                    <th key={index}>
                      <Form.Select
                        value={settings.columns[index]}
                        style={{ minWidth: 125 }}
                        onChange={(e) => {
                          console.log(1);
                          setSettings({
                            ...settings,
                            columns: settings.columns.map((el, ind) => {
                              if (ind === index) return e.target.value;
                              else return el;
                            }),
                          });
                        }}
                      >
                        <option value={null}></option>
                        {mealsFields.map((field) => (
                          <option value={field[0]}>{field[1]}</option>
                        ))}
                      </Form.Select>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {table.map((row, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {row.map((cell, index) => (
                      <td key={index}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
      )}

      <Modal.Footer>
        <Button
          variant="outline-secondary"
          onClick={() => setFilePopup(false)}
          disabled={isLoading}
        >
          {isLoading ? "Загрузка" : "Закрыть"}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (fileTimeStamp === null) onFileUpload();
            else onImport();
          }}
          disabled={isLoading}
        >
          {isLoading
            ? "Загрузка"
            : fileTimeStamp === null
            ? "Импортировать"
            : "Сохранить"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
