/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from "react";
import { Card, Button, Form, Modal, Accordion } from "react-bootstrap";
import { api } from "../utils/api";
import toBase64 from "../utils/toBase64";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../utils/UserContext";

export default function User({ userLogOut }) {
  const [form, setForm] = useState({
    name: "",
    avatar: "",
    email: "",
    password: "",
    mealtyCookie: "",
    _id: "",
    notifications: {},
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [filePopup, setFilePopup] = useState(false);
  const [tgLink, setTgLink] = useState(null);
  const userContext = React.useContext(UserContext);
  const [error, setError] = useState(null);

  const navigator = useNavigate();

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeNotify = (e) => {
    setForm({
      ...form,
      notifications: {
        ...form.notifications,
        [e.target.name]: !form.notifications[e.target.name],
      },
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (form._id !== "") {
      const { name, email, login, password, notifications, mealtyCookie } = form;
      api
        .patchMe({ name, email,login, password, notifications, mealtyCookie })
        .then(() => {
          setIsSuccess(true);
          setError(null);
        })
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onAvatarUpload = async (file) => {
    if (!file.type.match(/image\//)) {
      setError("Загружено не изображение");
      setFilePopup(false);
      return;
    }
    setIsLoading(true);
    const avatarInBase64 = await toBase64(file);
    api
      .uploadAvatar({ file: avatarInBase64, filename: file.name })
      .then(({ avatar }) => {
        setForm({ ...form, avatar });
        setFilePopup(false);
        setError(null);
      })
      .catch((err) =>
        err.json().then((mes) => {
          setError(JSON.stringify(mes));
          setFilePopup(false);
        })
      )
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    api.getMe().then(({ user, tgLink }) => {
      setForm(user);
      setTgLink(tgLink);
    });
  }, []);

  return (
    <>
      {isSuccess ? (
        <h3 className="m-0 text-success text-center mb-2">Успешно изменено!</h3>
      ) : (
        ""
      )}
      {error ? (
        <h3 className="m-0 text-danger text-center mb-2">{error}</h3>
      ) : (
        ""
      )}
      <Card>
        <Card.Header>
          <Card.Title>{form.name}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <img
              src={
                form.avatar === "" || form.avatar === undefined
                  ? "/api/media/avatars/avatar_def.jpg"
                  : form.avatar
              }
              style={{
                width: 100,
                height: 100,
                borderRadius: "50%",
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => setFilePopup(true)}
            />
            <Form.Group className="mb-3">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите имя"
                value={form.name}
                onChange={onChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Введите Email"
                value={form.email}
                onChange={onChange}
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Логин</Form.Label>
              <Form.Control
                type="login"
                placeholder="Введите Логин"
                value={form.login}
                onChange={onChange}
                name="login"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                type="password"
                placeholder="Введите новый Пароль"
                value={form.password}
                onChange={onChange}
                autocomplete="new-password"
                name="password"
              />
            </Form.Group>
            {userContext.access.application ? (
              <Form.Group className="mb-3">
                <Form.Label>Значение куки для Mealty</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Введите secret key"
                  value={form.mealtyCookie}
                  onChange={onChange}
                  autocomplete="new-password"
                  name="mealtyCookie"
                />
              </Form.Group>
            ) : (
              ""
            )}
          </Form>
          <Accordion className="mb-2" defaultActiveKey={["1"]} alwaysOpen>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Уведомления</Accordion.Header>
              <Accordion.Body>
                <div className="d-flex flex-column">
                  <Form.Check
                    className={`${
                      userContext.access.exchange ? "d-block" : "d-none"
                    } m-2`}
                    type="switch"
                    label=" Уведомлять о персональных предложениях обмена"
                    name="personalExchages"
                    checked={
                      form.notifications.personalExchages === true ? "on" : ""
                    }
                    onChange={onChangeNotify}
                  />
                  <Form.Check
                    className={`${
                      userContext.access.exchange ? "d-block" : "d-none"
                    } m-2`}
                    type="switch"
                    label=" Уведомлять о всех предложениях обмена"
                    name="allExhanges"
                    checked={
                      form.notifications.allExhanges === true ? "on" : ""
                    }
                    onChange={onChangeNotify}
                  />
                  <Form.Check
                    className={`${
                      userContext.access.application ? "d-block" : "d-none"
                    } m-2`}
                    type="switch"
                    label=" Уведомлять о необходимости сделать заявку"
                    name="applications"
                    checked={
                      form.notifications.applications === true ? "on" : ""
                    }
                    onChange={onChangeNotify}
                  />
                  {/* <Form.Check
                    className={`${
                      userContext.access.allowToNotify ? "d-block" : "d-none"
                    } m-2`}
                    type="switch"
                    label=" Уведомлять о необходимости заполнить блюда"
                    name="fillFood"
                    checked={form.notifications.fillFood === true ? "on" : ""}
                    onChange={onChangeNotify}
                  /> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {Boolean(tgLink) & Boolean(form.telegramId) ? (
            ""
          ) : (
            <a
              href={tgLink}
              target="_blank"
              className="btn btn-outline-primary"
            >
              Подключиться к боту
            </a>
          )}
        </Card.Body>
        <Card.Footer>
          <Button
            variant="danger"
            onClick={(e) => userLogOut()}
            className="me-2"
          >
            Выйти
          </Button>
          <Button
            variant="primary"
            onClick={(e) => onSubmit(e)}
            disabled={isLoading}
          >
            {isLoading ? "Сохраняем..." : "Сохранить"}
          </Button>
        </Card.Footer>
      </Card>
      <Modal show={filePopup} onHide={() => setFilePopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Загрузка аватара</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className="mb-3">
            <Form.Label>Выберите файл</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => onAvatarUpload(e.target.files[0])}
              accept="image/*"
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setFilePopup(false)}
            disabled={isLoading}
          >
            {isLoading ? "Загрузка" : "Закрыть"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
