import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { api } from "../utils/api";

export default function Team() {
  const [form, setForm] = useState({
    name: "",
    menuInclude: {
      mealty: false,
    },
    useExport: {
      mealty: false,
    },
  });

  useEffect(() => {
    api.getOurTeam().then(({ team }) => {
      setForm(team);
    });
  }, []);

  const onEditTeam = (e) => {
    e.preventDefault();
    const { name, menuInclude, useExport } = form;
    api.editOurTeam({ name, menuInclude, useExport }).then();
  };

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeSwitchExport = (e) => {
    setForm({
      ...form,
      useExport: {
        ...form.useExport,
        [e.target.name]: !form.useExport[e.target.name],
      },
    });
  };

  const onChangeSwitchMenu = (e) => {
    setForm({
      ...form,
      menuInclude: {
        ...form.menuInclude,
        [e.target.name]: !form.menuInclude[e.target.name],
      },
    });
  };

  return (
    <Card className="p-3">
      <Form onSubmit={(e) => onEditTeam(e)}>
        <Form.Group className="mb-3">
          <Form.Label>Название команды</Form.Label>
          <Form.Control
            type="text"
            placeholder="Введите название"
            value={form.name}
            onChange={onChange}
            name="name"
          />
        </Form.Group>
        <p className="mb-1 mt-3">Интеграция меню</p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          label=" Милти"
          name="mealty"
          checked={form.menuInclude.mealty === true ? "on" : ""}
          onChange={(e) => onChangeSwitchMenu(e)}
        />
        <p className="mb-1 mt-3">Интеграция заявок</p>
        <Form.Check
          type="switch"
          className="mt-2 mb-2"
          label=" Милти"
          name="mealty"
          checked={form.useExport.mealty === true ? "on" : ""}
          onChange={(e) => onChangeSwitchExport(e)}
        />
        <Card.Footer className="mt-3 d-flex justify-content-end">
          <Button variant="primary" type="submit">
            Сохранить
          </Button>
        </Card.Footer>
      </Form>
    </Card>
  );
}
