import React from "react";
import { Form, Button } from "react-bootstrap";

export default function SignIn({ signIn, form, onChange, isLoading }) {
  return (
    <Form onSubmit={signIn} className="card p-2">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Логин</Form.Label>
        <Form.Control
          type="text"
          placeholder="Введите Логин"
          value={form.login}
          onChange={onChange}
          name="login"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Пароль</Form.Label>
        <Form.Control
          type="password"
          placeholder="Введите пароль"
          value={form.password}
          onChange={onChange}
          name="password"
          autoComplete="current-password"
        />
      </Form.Group>
      <Button variant="primary" type="submit" disabled={isLoading}>
        Войти
      </Button>
    </Form>
  );
}
