import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Card,
  Form,
  Row,
  Col,
  Accordion,
} from "react-bootstrap";
import { api } from "../utils/api";
import { UserContext } from "../utils/UserContext";
import smallLogo from "../media/small_logo.svg";
import Rating from "react-rating";

const MealCard = ({ meal, setMeal }) => {
  const userContext = React.useContext(UserContext);
  const [reviews, setReviews] = useState(null);
  const [users, setUsers] = useState(null);
  const [rating, setRating] = useState(null);
  const [userHasRevieved, setUserHasRevieved] = useState(null);
  const [form, setForm] = useState({
    rating: null,
    text: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!meal) return;
    if (userContext.access.reviews !== true) return;
    api
      .getReviewsByProduct(meal.name)
      .then(({ reviews, rating, users, userHasRevieved }) => {
        setRating(rating);
        setUsers(users);
        setReviews(reviews);
        setUserHasRevieved(userHasRevieved);
      });
  }, [meal]);

  const onAddReview = () => {
    setIsLoading(true);
    api
      .createReview({
        product: meal.name,
        text: form.text,
        rating: form.rating,
      })
      .then(() => {
        api
          .getReviewsByProduct(meal.name)
          .then(({ reviews, rating, users }) => {
            setForm({
              rating: null,
              text: "",
            });
            setUserHasRevieved(true);
            setRating(rating);
            setReviews(reviews);
          })
          .finally(() => setIsLoading(false));
      });
  };

  const onEditReview = () => {
    setIsLoading(true);
    api
      .editReview({
        _id: form._id,
        text: form.text,
        rating: form.rating,
      })
      .then(() => {
        api
          .getReviewsByProduct(meal.name)
          .then(({ reviews, rating, users }) => {
            setForm({
              rating: null,
              text: "",
            });
            setRating(rating);
            setReviews(reviews);
          })
          .finally(() => setIsLoading(false));
      });
  };

  const onDeleteReview = (id) => {
    setIsLoading(true);
    api.deleteReview(id).then(() => {
      api
        .getReviewsByProduct(meal.name)
        .then(({ reviews, rating, users }) => {
          setRating(rating);
          setReviews(reviews);
          setUserHasRevieved(false);
        })
        .finally(() => setIsLoading(false));
    });
  };

  return (
    <Modal
      show={meal ? true : false}
      onHide={() => setMeal(null)}
      size="lg"
      fullscreen={"md"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Меню</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-3">
        {meal ? (
          <div className="d-flex flex-wrap mt-4 justify-content-center flex-column">
            <img
              src={meal.image ? meal.image : smallLogo}
              alt={meal.name}
              className="rounded"
              style={{ width: 280, margin: "0 auto" }}
            />
            <h2 className="mt-3 mb-1 d-flex flex-wrap justify-content-center">
              {meal.name}
            </h2>
            <h3 className="mb-2 d-flex flex-wrap justify-content-center text-center">
              {meal.subName}
            </h3>
            <div className="mb-3 d-flex flex-wrap justify-content-center align-items-center">
              {rating ? (
                <>
                  {" "}
                  <Rating
                    emptySymbol="bi bi-star text-primary fs-3"
                    fullSymbol="bi bi-star-fill text-primary fs-3"
                    stop={10}
                    readonly={true}
                    className="ms-2"
                    initialRating={rating}
                  />
                  <span className="ms-2">{rating.toFixed(1)}</span>
                </>
              ) : (
                ""
              )}
            </div>
            <h3 className="mb-6 text-center">{meal.price} р.</h3>
            <Accordion
              className="mb-2"
              defaultActiveKey={["1", "2"]}
              alwaysOpen
              style={{ width: "100%" }}
            >
              {meal.description ? (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Описание</Accordion.Header>
                  <Accordion.Body>
                    <p>{meal.description}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                ""
              )}
              <Accordion.Item eventKey="2">
                <Accordion.Header>КБЖУ</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="text-center">
                      <p className="m-0 mb-1 fs-5">Ккал</p>
                      <p className="m-0 fs-4 fw-bold">{meal.calories}</p>
                    </Col>
                    <Col className="text-center">
                      <p className="m-0 mb-1 fs-5">Белки</p>
                      <p className="m-0 fs-4 fw-bold">{meal.proteins}</p>
                    </Col>
                    <Col className="text-center">
                      <p className="m-0 mb-1 fs-5">Жиры</p>
                      <p className="m-0 fs-4 fw-bold">{meal.fats}</p>
                    </Col>
                    <Col className="text-center">
                      <p className="m-0 mb-1 fs-5">Углеводы</p>
                      <p className="m-0 fs-4 fw-bold">{meal.carbohydrates}</p>
                    </Col>
                  </Row>
                  <p className="mt-5">
                    Порция: {meal.weight} {meal.weightUnit}
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              {meal.products ? (
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Состав</Accordion.Header>
                  <Accordion.Body>
                    <p
                      className="fs-5"
                      style={{ height: 150, overflow: "scroll" }}
                    >
                      {meal.products}
                    </p>
                  </Accordion.Body>
                </Accordion.Item>
              ) : (
                ""
              )}
              {userContext.access.reviews ? (
                <>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>Отзывы</Accordion.Header>
                    <Accordion.Body>
                      {reviews
                        ? reviews.map((el) => (
                            <Card className="p-3">
                              {userContext._id === el.user ? (
                                <div className="position-absolute top-0 end-0 m-3">
                                  <i
                                    className="bi bi-pencil text-secondary  fs-3 me-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => setForm(el)}
                                  />
                                  <i
                                    className="bi bi-x-lg text-secondary fs-3 "
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => onDeleteReview(el._id)}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                              <div className="d-flex align-items-center">
                                <img
                                  src={
                                    users.find((le) => le._id === el.user)
                                      .avatar === "" ||
                                    users.find((le) => le._id === el.user)
                                      .avatar === undefined
                                      ? "/api/media/avatars/avatar_def.jpg"
                                      : users.find((le) => le._id === el.user)
                                          .avatar
                                  }
                                  alt={
                                    users.find((le) => le._id === el.user).name
                                  }
                                  style={{
                                    width: 60,
                                    height: 60,
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                                <p className="ms-3">
                                  {users.find((le) => le._id === el.user).name}
                                </p>
                              </div>
                              <Rating
                                emptySymbol="bi bi-star text-primary fs-3"
                                fullSymbol="bi bi-star-fill text-primary fs-3"
                                stop={10}
                                className="mt-2"
                                readonly={true}
                                initialRating={el.rating}
                              />
                              <p className="mt-2">{el.text}</p>
                            </Card>
                          ))
                        : ""}
                      {userHasRevieved !== true || form._id ? (
                        <Card className="mt-4">
                          <Card.Header>
                            {form._id ? "Изменить отзыв" : "Новый отзыв"}
                          </Card.Header>
                          <Card.Body>
                            <Rating
                              emptySymbol="bi bi-star text-primary fs-3"
                              fullSymbol="bi bi-star-fill text-primary fs-3"
                              stop={10}
                              onChange={(r) => setForm({ ...form, rating: r })}
                              initialRating={form.rating}
                            />
                            <Form.Control
                              type="text"
                              as="textarea"
                              placeholder="Напишите свой отзыв здесь"
                              value={form.text}
                              onChange={(e) =>
                                setForm({ ...form, text: e.target.value })
                              }
                              name="text"
                              rows={3}
                              className="mt-2"
                            />
                          </Card.Body>
                          <Card.Footer className="d-flex justify-content-end">
                            {form._id ? (
                              <Button
                                variant="primary"
                                onClick={(e) => onEditReview()}
                                disabled={
                                  isLoading ||
                                  form.text === "" ||
                                  form.rating === null
                                }
                              >
                                {isLoading ? "Изменяем..." : "Изменить"}
                              </Button>
                            ) : (
                              <Button
                                variant="primary"
                                onClick={(e) => onAddReview()}
                                disabled={
                                  isLoading ||
                                  form.text === "" ||
                                  form.rating === null
                                }
                              >
                                {isLoading ? "Сохраняем..." : "Сохранить"}
                              </Button>
                            )}
                          </Card.Footer>
                        </Card>
                      ) : (
                        ""
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                </>
              ) : (
                ""
              )}
            </Accordion>
          </div>
        ) : (
          ""
        )}
      </Modal.Body>
    </Modal>
  );
};

export default MealCard;
