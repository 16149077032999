import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Accordion } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import { UserContext } from "../../utils/UserContext";

export default function UsersForm() {
  const [form, setForm] = useState({
    name: "",
    isActive: true,
    email: "",
    login: "",
    password: "",
    access: {},
    _id: "",
    mealtyCookie: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const navigator = useNavigate();
  const { id, userId } = useParams();
  const userContext = React.useContext(UserContext);

  const onChange = (e) => {
    if (
      (e.target.name === "email") &
      (form.login === "" || form.login === form.email)
    )
      return setForm({ ...form, email: e.target.value, login: e.target.value });
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeAccess = (e) => {
    setForm({
      ...form,
      access: { ...form.access, [e.target.name]: !form.access[e.target.name] },
    });
  };

  const closePopup = () => {
    setForm({
      name: "",
      email: "",
      access: {},
      _id: "",
      mealtyCookie: "",
    });
    if (userId) navigator("/teams/" + form.teamId);
    else navigator("/users");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (form._id !== "") {
      const {
        name,
        email,
        password,
        access,
        _id,
        mealtyCookie,
        isActive,
        login,
      } = form;
      api
        .editUser({
          name,
          email,
          login,
          password,
          access,
          _id,
          mealtyCookie,
          isActive,
        })
        .then(() => closePopup())
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      const { name, email, password, access, mealtyCookie, isActive, login } =
        form;
      api
        .postUser({
          name,
          email,
          password,
          access,
          mealtyCookie,
          isActive,
          login,
        })
        .then(() => closePopup())
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    setIsLoading(true);
    api
      .deleteUser(form._id)
      .then(() => closePopup())
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id === "new") return;
    api
      .getUser(userId ? userId : id)
      .then(({ user }) => setForm(user))
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);

  return (
    <>
      <Modal show={true} onHide={() => closePopup()} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{form.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="switch"
              className="mt-4 mb-4"
              label=" Активность"
              name="isActive"
              checked={form.isActive === true ? "on" : ""}
              onChange={(e) =>
                setForm({
                  ...form,
                  isActive: !form.isActive,
                })
              }
            />
            <Form.Group className="mb-3">
              <Form.Label>Имя</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите имя"
                value={form.name}
                onChange={onChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Введите Email"
                value={form.email}
                onChange={onChange}
                name="email"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Логин</Form.Label>
              <Form.Control
                type="email"
                placeholder="Введите Логин"
                value={form.login}
                onChange={onChange}
                name="login"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Пароль</Form.Label>
              <Form.Control
                type="password"
                placeholder="Введите новый Пароль"
                value={form.password}
                onChange={onChange}
                autocomplete="new-password"
                name="password"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Значение куки для Mealty</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите secret key"
                value={form.mealtyCookie}
                onChange={onChange}
                autocomplete="new-password"
                name="mealtyCookie"
              />
            </Form.Group>
            <Accordion className="mb-2" defaultActiveKey={["1"]} alwaysOpen>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Доступы</Accordion.Header>
                <Accordion.Body>
                  <div className="d-flex flex-column">
                    <Form.Check
                      className={`${
                        userContext.access.isTeamAdmin ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Администратор команды"
                      name="isTeamAdmin"
                      checked={form.access.isTeamAdmin === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.exchange ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Разрешить обмениваться блюдами"
                      name="exchange"
                      checked={form.access.exchange === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.application ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Заявки"
                      name="application"
                      checked={form.access.application === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.fridge ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Холодильник"
                      name="fridge"
                      checked={form.access.fridge === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.report ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Отчет"
                      name="report"
                      checked={form.access.report === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.ordersAll ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Вся еда"
                      name="ordersAll"
                      checked={form.access.ordersAll === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.ordersMy ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Своя еда"
                      name="ordersMy"
                      checked={form.access.ordersMy === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.meals ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Блюда"
                      name="meals"
                      checked={form.access.meals === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.editOrderedOrders
                          ? "d-block"
                          : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Разрешить изменять заказанные позиции"
                      name="editOrderedOrders"
                      checked={
                        form.access.editOrderedOrders === true ? "on" : ""
                      }
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.users ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Пользователи"
                      name="users"
                      checked={form.access.users === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.limits ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Лимиты"
                      name="limits"
                      checked={form.access.limits === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.cancelToPay ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Отмена оплаты блюда"
                      name="cancelToPay"
                      checked={form.access.cancelToPay === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    <Form.Check
                      className={`${
                        userContext.access.reviews ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Отзывы на блюда"
                      name="reviews"
                      checked={form.access.reviews === true ? "on" : ""}
                      onChange={onChangeAccess}
                    />
                    {/* <Form.Check
                      className={`${
                        userContext.access.allowToNotify ? "d-block" : "d-none"
                      } m-2`}
                      type="switch"
                      label=" Разрешить уведомлять о необходимости заказать себе еду"
                      name="allowToNotify"
                      checked={form.access.allowToNotify === true ? "on" : ""}
                      onChange={onChangeAccess}
                    /> */}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {form._id !== "" ? (
            <Button variant="danger" onClick={(e) => setIsDeleting(true)}>
              Удалить
            </Button>
          ) : (
            ""
          )}

          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          <Button
            variant="primary"
            onClick={(e) => onSubmit(e)}
            disabled={isLoading}
          >
            {isLoading ? "Сохраняем..." : "Сохранить"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isDeleting} onHide={() => setIsDeleting(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Вы уверены?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setIsDeleting(false)}
          >
            Закрыть
          </Button>
          <Button variant="danger" onClick={(e) => onDelete(e)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
