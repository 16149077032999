import React, { useState, useEffect } from "react";
import {
  Table,
  Pagination,
  Form,
  Button,
  Accordion,
  Row,
  Col,
} from "react-bootstrap";
import { api } from "../../utils/api";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import urlParser from "../../utils/urlParser";
registerLocale("ru", ru);

export default function Report() {
  const [data, setData] = useState(null);
  const [querryParams, setQuerryParams] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigator = useNavigate();
  const location = useLocation();

  const onSubmit = (urlParams) => {
    setIsLoading(true);
    api
      .getReport(urlParams.f_datef ? urlParams : querryParams)
      .then(({ report }) => setData(report))
      .catch((err) => err.status === 403 && navigator("/access_denied"))
      .finally(() => setIsLoading(false));
  };

  const onSubmitExcel = () => {
    setIsLoading(true);
    api
      .getReportExcel(querryParams)
      .then(({ path }) => {
        window.open(path, "_blank");
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (JSON.stringify(querryParams) === "{}") return;
    let querryString = "?";
    if (querryParams.f_datef)
      querryString += `f_datef=${new Date(
        querryParams.f_datef
      ).toISOString()}&`;
    if (querryParams.f_datet)
      querryString += `f_datet=${new Date(querryParams.f_datet).toISOString()}`;
    window.history.pushState(
      null,
      "",
      `${window.location.pathname}${querryString}`
    );
  }, [querryParams]);

  useEffect(() => {
    const querryObject = urlParser(window.location.search);
    if (JSON.stringify(querryObject) === "{}" ||JSON.stringify(querryObject) === `{"":"undefined"}`) return;
    setQuerryParams({
      f_datef: new Date(querryObject.f_datef),
      f_datet: new Date(querryObject.f_datet),
    });
    onSubmit(querryObject);
  }, [location]);

  return (
    <>
      <h1>Финансовый отчет</h1>
      <Accordion defaultActiveKey="0" className="mb-2">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Поиск</Accordion.Header>
          <Accordion.Body>
            <Form.Group className="mb-3">
              <Form.Label>С</Form.Label>
              <DatePicker
                selected={querryParams.f_datef}
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(f_datef) =>
                  setQuerryParams({ ...querryParams, f_datef })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>По</Form.Label>
              <DatePicker
                className="form-control"
                locale="ru"
                dateFormat="dd.MM.yyyy"
                selected={querryParams.f_datet}
                onChange={(f_datet) =>
                  setQuerryParams({ ...querryParams, f_datet })
                }
              />
            </Form.Group>
            <Row style={{ maxWidth: 500 }}>
              <Col>
                <Button
                  variant="primary"
                  onClick={(e) => onSubmit(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "Формируем..." : "Cформировать"}
                </Button>
              </Col>
              <Col>
                <Button
                  variant="primary"
                  onClick={(e) => onSubmitExcel(e)}
                  disabled={isLoading}
                  className="ms-2"
                >
                  {isLoading ? "Формируем..." : "Cформировать в Excel"}
                </Button>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {data ? (
        <>
          <div style={{ maxWidth: "98vw", overflow: "scroll" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Имя</th>
                  {data[0].orders.map((col, index) => (
                    <th key={index}>
                      {new Date(col.date).toLocaleDateString("ru-RU", {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      })}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {data.map((user) => (
                  <tr key={user._id}>
                    <td>{user.name}</td>
                    {user.orders.map((order) => (
                      <td>{order.order ? order.order.total : 0}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div style={{ maxWidth: "98vw", overflow: "scroll" }}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Имя</th>
                  <th>Итого</th>
                  <th>Заказов</th>
                  <th>Лимит</th>
                  <th>К оплате</th>
                </tr>
              </thead>
              <tbody>
                {data.map((user) => (
                  <tr key={user._id}>
                    <td>{user.name}</td>
                    <td>{user.total}</td>
                    <td>{user.countOfOrders}</td>
                    <td>{user.limit}</td>
                    <td>{user.toPay}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
}
