import React, { useState, useEffect } from "react";
import { api } from "../utils/api";
import logo from "../media/logo.svg";
import SignIn from "./SignIn";
import SignUp from "./SignUp";

export default function Auth({ userAuth }) {
  const [mode, setMode] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [form, setForm] = useState({
    name: "",
    password: "",
    login: "",
    email: "",
    teamName: "",
    mealtyMenu: false,
    mealtyExport: false,
  });

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onChangeCheck = (e) => {
    setForm({
      ...form,
      [e.target.name]: !form[e.target.name],
    });
  };

  const signIn = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { password, login } = form;
    api
      .signIn({ password, login })
      .then((res) => {
        userAuth(res);
        setError(null);
      })
      .catch((res) => {
        res.json().then(({ message }) => setError(message));
      })
      .finally(() => setIsLoading(false));
  };

  const signUp = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { password, email, name, teamName, mealtyMenu, mealtyExport } = form;
    api
      .signUp({ password, email, name, teamName, mealtyMenu, mealtyExport })
      .then((res) => {
        setMode(1);
        setError(null);
      })
      .catch((res) => {
        res.json().then(({ message }) => setError(message));
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div
      className="d-flex flex-column align-items-center justify-content-center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <div
        className="d-flex flex-column"
        style={{ width: "60%", minWidth: 300 }}
      >
        <h1 className="text-primary mb-2 ps-3">
          <img
            alt="Лого"
            src={logo}
            style={{ width: 250 }}
            className="mb-3 me-2"
          />
        </h1>
        {mode === 1 ? (
          <SignIn signIn={signIn} form={form} onChange={onChange} isLoading={isLoading}/>
        ) : (
          <SignUp signUp={signUp} form={form} onChange={onChange} onChangeCheck={onChangeCheck} isLoading={isLoading}/>
        )}
        {error ? (
          <p className="text-danger fs-light text-center mt-2">{error}</p>
        ) : (
          ""
        )}
        <p
          className="mt-2 fs-4 text-center text-muted"
          style={{ cursor: "pointer" }}
          onClick={() => (mode === 1 ? setMode(2) : setMode(1))}
        >
          {mode === 2 ? "Войти" : "Зарегистрировать команду"}
        </p>
      </div>
    </div>
  );
}
