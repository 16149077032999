import React, { useState } from "react";
import { useEffect } from "react";
import { Card, Form, Accordion, Table, Button } from "react-bootstrap";
import { api } from "../utils/api";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export default function Limits() {
  const [totalData, setTotalData] = useState([]);
  const [users, setUsers] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [form, setForm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [failed, setIsFailed] = useState(false);
  const [creatingFailed, setCreatingFailed] = useState(false);
  const [needUpdate, setNeedUpdate] = useState(true);

  useEffect(() => {
    if (!needUpdate) return;
    api
      .getLimits()
      .then(({ limits, users }) => {
        setTotalData(limits);
        setUsers(users);
        if (limits.length > 0) setActiveForm(0);
      })
      .finally(() => setNeedUpdate(false));
  }, [needUpdate]);

  useEffect(() => {
    if (activeForm === null) return;
    if (activeForm === "new") {
      api
        .getNextDatePeriod()
        .then(({ lastDay }) =>
          setForm({
            dateTo: null,
            dateFrom: lastDay,
            users: [],
            globalType: null,
            globalLimit: null,
          })
        )
        .catch((err) =>
          err.json().then(({ message }) => setCreatingFailed(message))
        );
    } else setForm(totalData[activeForm]);
  }, [activeForm]);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const changeForm = (type) => {
    switch (type) {
      case "+":
        if (totalData.length > activeForm + 1) setActiveForm(activeForm + 1);
        break;
      case "-":
        if (0 <= activeForm - 1) setActiveForm(activeForm - 1);
        break;
    }
  };

  const onSubmit = (e) => {
    setIsLoading(true);
    api
      .createLimit(form)
      .then((res) => {})
      .catch((err) => err.json(({ message }) => setIsFailed(message)))
      .finally(() => {
        setIsLoading(false);
        setNeedUpdate(true);
      });
  };

  const onPatch = (e) => {
    setIsLoading(true);
    const { dateTo, users, globalLimit, globalType, _id } = form;
    api
      .updateLimit({ dateTo, users, globalLimit, globalType, _id })
      .then((res) => {})
      .catch((err) => err.json(({ message }) => setIsFailed(message)))
      .finally(() => {
        setIsLoading(false);
        setNeedUpdate(true);
      });
  };

  return (
    <>
      <h1>Лимиты</h1>
      {(activeForm !== null) & (form !== null) ? (
        <div className="d-flex align-items-center justify-content-center flex-column">
          <div
            className={`d-flex align-items-center justify-content-center mb-3 ${
              activeForm === "new" ? " text-decoration-underline" : ""
            }`}
            onClick={(e) => {
              if (activeForm === "new") {
                setActiveForm(0);
                setCreatingFailed(false);
              }
            }}
            style={{ cursor: activeForm === "new" ? "pointer" : "inherit" }}
          >
            {0 <= activeForm - 1 ? (
              <i
                className="bi bi-chevron-left me-2 fs-3"
                onClick={() => changeForm("-")}
                style={{ cursor: "pointer" }}
              />
            ) : (
              ""
            )}
            {activeForm === "new"
              ? new Date(totalData[0].dateFrom).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : new Date(form.dateFrom).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}{" "}
            -
            {activeForm === "new"
              ? totalData[0].dateTo
                ? new Date(totalData[0].dateTo).toLocaleDateString("ru-RU", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
                : " ..."
              : form.dateTo
              ? new Date(form.dateTo).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : " ..."}
            {totalData.length > activeForm + 1 ? (
              <i
                className="bi bi-chevron-right fs-3 ms-2"
                onClick={() => changeForm("+")}
                style={{ cursor: "pointer" }}
              />
            ) : (
              ""
            )}
          </div>
          <Button
            variant={creatingFailed ? "outline-danger" : "outline-primary"}
            onClick={(e) => setActiveForm("new")}
            disabled={creatingFailed !== false}
            className="mb-3"
          >
            {creatingFailed ? creatingFailed : "Добавить новое правило"}
          </Button>
        </div>
      ) : (
        <Button
          variant="outline-primary"
          onClick={(e) => setForm({})}
          className="mb-3"
        >
          Добавить новое правило
        </Button>
      )}
      {form !== null ? (
        <Card>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Начало периода</Form.Label>
                <DatePicker
                  selected={form.dateFrom ? new Date(form.dateFrom) : null}
                  className="form-control"
                  disabled={totalData.length > 0}
                  locale="ru"
                  dateFormat="dd.MM.yyyy"
                  onChange={(dateFrom) => setForm({ ...form, dateFrom })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Конец периода</Form.Label>
                <DatePicker
                  selected={form.dateTo ? new Date(form.dateTo) : null}
                  className="form-control"
                  locale="ru"
                  dateFormat="dd.MM.yyyy"
                  onChange={(dateTo) => setForm({ ...form, dateTo })}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Тип ограничений по умолчанию</Form.Label>
                <Form.Select
                  value={form.globalType}
                  onChange={onChange}
                  name="globalType"
                >
                  <option value="meals">По блюдам</option>
                  <option value="limit">По сумме</option>
                </Form.Select>
              </Form.Group>
              {form.globalType === "limit" ? (
                <Form.Control
                  type="number"
                  placeholder="Введите сумму"
                  className="mt-1"
                  value={form.globalLimit}
                  onChange={onChange}
                  name="globalLimit"
                  clas
                />
              ) : (
                ""
              )}
            </Form>
            <Accordion
              className="mb-2 mt-3"
              defaultActiveKey={["1"]}
              alwaysOpen
            >
              <Accordion.Item eventKey="1">
                <Accordion.Header>Пользователи</Accordion.Header>
                <Accordion.Body>
                  {form.users && form.users.length > 0 ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th></th>
                          <th>Пользователь</th>
                          <th>Тип</th>
                          <th>Сумма</th>
                        </tr>
                      </thead>
                      <tbody>
                        {form.users.map((el, ind) => (
                          <tr>
                            <td>{ind + 1}</td>
                            <td>
                              <Form.Select
                                value={form.users[ind].user}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    users: form.users.map((element, index) => {
                                      if (index === ind)
                                        return {
                                          ...element,
                                          user: e.target.value,
                                        };
                                      else return element;
                                    }),
                                  })
                                }
                              >
                                <option value=""></option>
                                {users.map((el) => (
                                  <option value={el._id} key={el._id}>
                                    {el.name}
                                  </option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Select
                                value={form.users[ind].type}
                                onChange={(e) =>
                                  setForm({
                                    ...form,
                                    users: form.users.map((element, index) => {
                                      if (index === ind)
                                        return {
                                          ...element,
                                          type: e.target.value,
                                        };
                                      else return element;
                                    }),
                                  })
                                }
                                name="type"
                              >
                                <option value="meals">По блюдам</option>
                                <option value="limit">По сумме</option>
                              </Form.Select>
                            </td>
                            <td>
                              {form.users[ind].type === "limit" ? (
                                <Form.Control
                                  type="number"
                                  placeholder="Введите сумму"
                                  name="limit"
                                  value={form.users[ind].limit}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      users: form.users.map(
                                        (element, index) => {
                                          if (index === ind)
                                            return {
                                              ...element,
                                              limit: e.target.value,
                                            };
                                          else return element;
                                        }
                                      ),
                                    })
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td colspan={4}>
                            <Button
                              onClick={(e) =>
                                setForm({ ...form, users: [...form.users, {}] })
                              }
                            >
                              Добавить пользователя
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  ) : (
                    <Button
                      onClick={(e) =>
                        setForm({
                          ...form,
                          users: [{ user: "", type: "", limit: "" }],
                        })
                      }
                    >
                      Добавить правила
                    </Button>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Card.Body>
          <Card.Footer>
            <div className="d-flex justify-content-end">
              {form._id ? (
                <Button
                  variant="primary"
                  onClick={(e) => onPatch(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "Сохраняем..." : "Сохранить"}
                </Button>
              ) : (
                <Button
                  variant={failed ? "danger" : "primary"}
                  onClick={(e) => onSubmit(e)}
                  disabled={isLoading || failed !== false}
                >
                  {failed !== false
                    ? failed
                    : isLoading
                    ? "Сохраняем..."
                    : "Сохранить"}
                </Button>
              )}
            </div>
          </Card.Footer>
        </Card>
      ) : (
        ""
      )}
    </>
  );
}
