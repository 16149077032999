import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import smallLogo from "../../media/small_logo.svg";
import { UserContext } from "../../utils/UserContext";

export default function MealsPopup() {
  const [form, setForm] = useState({
    name: "",
    subName: "",
    isActive: "",
    price: "",
    image: "",
    _id: "",
    weightUnit: "",
    weight: "",
    description: "",
    proteins: "",
    fats: "",
    carbohydrates: "",
    calories: "",
    products: "",
    group: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const userContext = useContext(UserContext);
  const navigator = useNavigate();
  const { id } = useParams();

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const closePopup = () => {
    setForm({
      name: "",
      subName: "",
      isActive: "",
      price: "",
      image: "",
      _id: "",
      weightUnit: "",
      weight: "",
      description: "",
      proteins: "",
      fats: "",
      carbohydrates: "",
      calories: "",
      products: "",
      group: "",
    });
    navigator("/meals");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (form._id !== "") {
      const {
        name,
        subName,
        isActive,
        price,
        image,
        _id,
        weightUnit,
        weight,
        description,
        proteins,
        fats,
        carbohydrates,
        calories,
        products,
        group,
      } = form;
      api
        .editMeal({
          name,
          subName,
          isActive,
          price,
          image,
          _id,
          weightUnit,
          weight,
          description,
          proteins,
          fats,
          carbohydrates,
          calories,
          products,
          group,
        })
        .then(() => closePopup())
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      const {
        name,
        subName,
        isActive,
        price,
        image,
        weightUnit,
        weight,
        description,
        proteins,
        fats,
        carbohydrates,
        calories,
        products,
        group,
      } = form;
      api
        .postMeal({
          name,
          subName,
          isActive,
          price: Number(price),
          image,
          weightUnit,
          weight,
          description,
          proteins,
          fats,
          carbohydrates,
          calories,
          products,
        })
        .then(() => closePopup())
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onDelete = () => {
    setIsLoading(true);
    api
      .deleteMeal(form._id)
      .then(() => closePopup())
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id === "new") return;
    api
      .getMeal(id)
      .then(({ meal }) => setForm(meal))
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);

  return (
    <>
      <Modal show={true} onHide={() => closePopup()} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>{form.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Check
                type="switch"
                className="mt-4 mb-4"
                label=" Активность"
                name="isActive"
                checked={form.isActive === true ? "on" : ""}
                onChange={(e) =>
                  setForm({
                    ...form,
                    isActive: !form.isActive,
                  })
                }
              />
              <Form.Label>Название</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите название"
                value={form.name}
                onChange={onChange}
                name="name"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Подназвание</Form.Label>
              <Form.Control
                type="text"
                placeholder="Введите название"
                value={form.subName}
                onChange={onChange}
                name="subName"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Цена</Form.Label>
              <Form.Control
                type="number"
                placeholder="Введите цену"
                value={form.price}
                onChange={onChange}
                name="price"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Изображение</Form.Label>
              <Form.Control
                type="text"
                placeholder="Укажите url изображения"
                value={form.image}
                onChange={onChange}
                name="image"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Группа</Form.Label>
              <Form.Control
                type="text"
                placeholder="Укажите название группы продукта"
                value={form.group}
                onChange={onChange}
                name="group"
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Вес</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Укажите вес"
                    value={form.weight}
                    onChange={onChange}
                    name="weight"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Ед. измерения</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Укажите ед. измерения"
                    value={form.weightUnit}
                    onChange={onChange}
                    name="weightUnit"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Ккал</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="К"
                    value={form.calories}
                    onChange={onChange}
                    name="calories"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Белки</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Б"
                    value={form.proteins}
                    onChange={onChange}
                    name="proteins"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Жиры</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Ж"
                    value={form.fats}
                    onChange={onChange}
                    name="fats"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Углеводы</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="У"
                    value={form.carbohydrates}
                    onChange={onChange}
                    name="carbohydrates"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Описание"
                value={form.description}
                onChange={onChange}
                name="description"
                rows={6}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Состав</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                placeholder="Состав"
                value={form.products}
                onChange={onChange}
                name="products"
                rows={6}
              />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <img
                src={form.image ? form.image : smallLogo}
                alt={form.name}
                className="rounded float-start"
                style={{ width: 250 }}
              />
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          {(userContext.isRoot || form.menuType !== "mealty") &
          (form._id !== "") ? (
            <Button variant="danger" onClick={(e) => setIsDeleting(true)}>
              Удалить
            </Button>
          ) : (
            ""
          )}
          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          {userContext.isRoot || form.menuType !== "mealty" ? (
            <Button
              variant="primary"
              onClick={(e) => onSubmit(e)}
              disabled={isLoading}
            >
              {isLoading ? "Сохраняем..." : "Сохранить"}
            </Button>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isDeleting} onHide={() => setIsDeleting(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Вы уверены?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setIsDeleting(false)}
          >
            Закрыть
          </Button>
          <Button variant="danger" onClick={(e) => onDelete(e)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
