import React, { useEffect, useState } from "react";
import { api } from "../utils/api";
import { Modal, Form, Button, InputGroup } from "react-bootstrap";
import { UserContext } from "../utils/UserContext";

const ExchangePopup = ({ meal, setMeal, me, setNeedUpdate }) => {
  const [userTo, setUserTo] = useState(undefined);
  const [amount, setAmount] = useState(1);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const userContext = React.useContext(UserContext);
  const closePopup = () => {
    setMeal(null);
    setUserTo(undefined);
    setAmount(1);
  };
  useEffect(() => {
    api.getUsers({ p: 1, s: 10000 }).then(({ users }) => setUsers(users));
  }, []);
  const onSubmit = () => {
    setIsLoading(true);
    api
      .createExchange({
        userTo: userTo === "forAll" ? undefined : userTo,
        orderId: meal.orderId,
        name: meal.name,
        price: meal.price,
        amount,
        mealId: meal.mealId,
      })
      .then(() => {
        closePopup();
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        setNeedUpdate(true);
      });
  };

  return (
    <Modal show={meal ? true : false} onHide={() => closePopup()}>
      <Modal.Header closeButton>
        <Modal.Title>Выставить блюдо</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3">
            <Form.Label>Имя</Form.Label>
            <Form.Select
              value={userTo}
              onChange={(e) => setUserTo(e.target.value)}
            >
              <option value="forAll">Для всех</option>
              {users
                .filter((el) => (!me ? el._id !== userContext._id : true))
                .map((el) => (
                  <option value={el._id} key={el._id}>
                    {el.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
          {meal ? (
            <>
              <Form.Group className="mb-3">
                <Form.Label>Что</Form.Label>
                <p className="fs-23 fw-bold ps-2">
                  {meal.name} за {meal.price} рублев
                </p>
              </Form.Group>
            </>
          ) : (
            ""
          )}
          <Form.Group className="mb-3">
            <Form.Label>Сколько</Form.Label>
            <InputGroup size="lg">
              <Button
                onClick={(e) =>{
                  if (0 < amount - 1) {
                    setAmount(Number(amount) - 1)
                  }
                }
                }
                variant="outline-secondary"
                className="fs-3"
              >
                -
              </Button>
              <Form.Control
                value={amount}
                onChange={(e) => {
                  if ((e.target.value > 0) & (e.target.value < meal.amount))
                    setAmount(e.target.value);
                }}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                  width: 50,
                  textAlign: "center",
                }}
              />
              <Button
                variant="outline-secondary"
                className="fs-3"
                onClick={(e) =>{
                  if (meal.amount >= amount + 1) {
                    setAmount(Number(amount) + 1)
                  }
                }
                }
              >
                +
              </Button>
            </InputGroup>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={() => closePopup()}>
          Закрыть
        </Button>
        <Button
          variant="primary"
          onClick={(e) => onSubmit(e)}
          disabled={isLoading}
        >
          {isLoading ? "Выставляем..." : "Выставить"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ExchangePopup;
