import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Auth from "./components/Auth";
import NavBar from "./components/NavBar";
import MealsTable from "./components/Meals/MealsTable";
import MealsPopup from "./components/Meals/MealsPopup";
import MyOrders from "./components/MyOrders";
import AllOrders from "./components/AllOrders";
import ApplicationsTable from "./components/Applications/ApplicationsTable";
import ApplicationsForm from "./components/Applications/ApplicationsForm";
import Fridge from "./components/Fridge";
import UsersTable from "./components/Users/UsersTable";
import UsersForm from "./components/Users/UsersForm";
import User from "./components/User";
import Report from "./components/Reports/Report";
import { api } from "./utils/api";
import { UserContext } from "./utils/UserContext";
import Preloader from "./components/Preloader";
import Exchanges from "./components/Exchanges";
import { Alert } from "react-bootstrap";
import Limits from "./components/Limits";
import { TeamContext } from "./utils/TeamContext";
import Team from "./components/Team";
import TeamsTable from "./components/Teams/TeamsTable";
import TeamsForm from "./components/Teams/TeamsForm";

function App() {
  const [user, setUser] = useState(null);
  const [team, setTeam] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const mainLayoutClasses = "d-flex p-3 container-fluid flex-column";
  const mainLayoutStyle = {maxWidth: 1540};

  React.useEffect(() => {
    setIsLoading(true);
    Promise.all([api.getMe(), api.getOurTeam()])
      .then(([user, team]) => {
        setUser(user.user);
        setTeam(team.team);
      })
      .catch(() => {
        setUser(undefined);
        setTeam(undefined);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const authorization = () => {
    setIsLoading(true);
    Promise.all([api.getMe(), api.getOurTeam()])
      .then(([user, team]) => {
        setUser(user.user);
        setTeam(team.team);
      })
      .catch(() => {
        setUser(undefined);
        setTeam(undefined);
      })
      .finally(() => setIsLoading(false));
  };

  const userLogOut = () => {
    setIsLoading(true);
    api
      .userLogOut()
      .then(() => {
        window.location.href = "/";
        setUser(undefined);
        setTeam(undefined);
      })
      .finally(() => setIsLoading(false));
  };

  const routerAuth = createBrowserRouter([
    {
      path: "/*",
      element: <Auth userAuth={authorization} />,
    },
  ]);

  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <MyOrders />
          </div>
        </>
      ),
      errorElement: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <h1 className="text-center mt-5">
              Такая страница еще не придумана
            </h1>
            <p className="text-center text-primary" style={{ fontSize: 112 }}>
              404
            </p>
          </div>
        </>
      ),
    },
    {
      path: "/access_denied",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <h1 className="text-center mt-5">Доступ запрещен</h1>
            <p className="text-center text-primary" style={{ fontSize: 112 }}>
              403
            </p>
          </div>
        </>
      ),
    },
    {
      path: "/me",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <User userLogOut={userLogOut} />
          </div>
        </>
      ),
    },
    {
      path: "/users",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <UsersTable />
          </div>
        </>
      ),
    },
    {
      path: "/my_orders",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <MyOrders />,
          </div>
        </>
      ),
    },
    {
      path: "/all_orders",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <AllOrders />
          </div>
        </>
      ),
    },
    {
      path: "/meals",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <MealsTable />
          </div>
        </>
      ),
    },
    {
      path: "/report",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <Report />
          </div>
        </>
      ),
    },
    {
      path: "/applications",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <ApplicationsTable />
          </div>
        </>
      ),
    },
    {
      path: "/exchanges",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <Exchanges />
          </div>
        </>
      ),
    },
    {
      path: "/fridge",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <Fridge />
          </div>
        </>
      ),
    },
    {
      path: "/applications/:id",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <ApplicationsForm />
          </div>
        </>
      ),
    },
    {
      path: "/meals/:id",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <MealsPopup />
          </div>
        </>
      ),
    },
    {
      path: "/users/:id",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <UsersForm />
          </div>
        </>
      ),
    },
    {
      path: "/limits",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <Limits />
          </div>
        </>
      ),
    },
    {
      path: "/team",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <Team />
          </div>
        </>
      ),
    },
    {
      path: "/teams",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <TeamsTable />
          </div>
        </>
      ),
    },
    {
      path: "/teams/:id",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <TeamsForm />
          </div>
        </>
      ),
    },
    {
      path: "/teams/:id/:userId",
      element: (
        <>
          <NavBar />
          <div className={mainLayoutClasses} style={mainLayoutStyle}>
            <UsersForm />
          </div>
        </>
      ),
    },
  ]);

  useEffect(() => {
    const offline = () => {
      setIsOffline(true);
    };
    const online = () => {
      setIsOffline(false);
    };

    window.addEventListener("offline", offline);

    window.addEventListener("online", online);
    return () => {
      window.removeEventListener("offline", offline);

      window.removeEventListener("online", online);
    };
  }, []);

  return (
    <UserContext.Provider value={user}>
      <TeamContext.Provider value={team}>
        {isOffline ? (
          <Alert variant="danger" className="m-0">
            Нет интернет соединения, данные доступны только для чтения.
          </Alert>
        ) : (
          ""
        )}
        {user ? (
          <RouterProvider router={router} />
        ) : (
          <RouterProvider router={routerAuth} />
        )}
        {isLoading ? <Preloader /> : ""}
      </TeamContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
