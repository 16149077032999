import React, { useState, useEffect, useContext } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../utils/api";
import DatePicker, { registerLocale } from "react-datepicker";
import { TeamContext } from "../../utils/TeamContext";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export default function ApplicationsForm() {
  const [form, setForm] = useState({
    dateTo: new Date(),
    dateFrom: new Date(),
    meals: [],
    status: "notOrdered",
    owner: "",
    _id: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [users, setUsers] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [mealtyError, setMealtyError] = useState("");
  const [confirmError, setConfirmError] = useState("");
  const [success, setSuccess] = useState(false);
  const teamContext = useContext(TeamContext);
  const navigator = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    let total = 0;
    form.meals.forEach((el) => (total += el.price * el.count));
    setTotal(total);
  }, [form]);

  const closePopup = () => {
    setForm({
      dateTo: new Date(),
      dateFrom: new Date(),
      meals: [],
      _id: "",
    });
    navigator("/applications");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (form._id === "") {
      const { dateFrom, dateTo, meals } = form;
      api
        .postApplication({ dateFrom, dateTo, meals })
        .then(() => setSuccess(true))
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    } else {
      const { meals, _id } = form;
      api
        .updateApplication({ applicationId: _id, meals })
        .then(() => setSuccess(true))
        .catch((err) => console.log(err))
        .finally(() => setIsLoading(false));
    }
  };

  const onSubmitAndSend = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (form._id === "") {
      const { dateFrom, dateTo, meals } = form;
      api
        .postApplication({ dateFrom, dateTo, meals })
        .then(({ newApplication }) =>
          onSendToMealty({ applicationId: newApplication._id })
        )
        .catch((err) => console.log(err));
    } else {
      const { meals, _id } = form;
      api
        .updateApplication({ applicationId: _id, meals })
        .then(() => onSendToMealty({ applicationId: _id }))
        .catch((err) => console.log(err));
    }
  };

  const onSendToMealty = ({ applicationId }) => {
    api
      .sendMealty({ applicationId })
      .then((res) => setSuccess(true))
      .catch((err) => err.json().then(({ message }) => setMealtyError(message)))
      .finally(() => {
        onUpdateInfo(applicationId);
        setIsLoading(false);
      });
  };

  const onDelete = () => {
    setIsLoading(true);
    api
      .deleteApplication(form._id)
      .then(() => closePopup())
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id === "new") return;
    api
      .getApplication(id)
      .then(({ application, users }) => {
        setForm(application);
        setUsers(users);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  }, []);

  const onUpdateInfo = (applicationId) => {
    api
      .getApplication(applicationId)
      .then(({ application }) => {
        setForm(application);
      })
      .catch((err) => err.status === 403 && navigator("/access_denied"));
  };

  const onRequestMeals = () => {
    setIsLoading(true);
    const { dateFrom, dateTo, _id } = form;
    api
      .requestApplication({ dateFrom, dateTo, applicationId: _id })
      .then(({ meals }) => setForm({ ...form, meals }))
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  const mealRemove = (name) => {
    setForm({
      ...form,
      meals: form.meals.filter((meal) => meal.name !== name),
    });
  };

  const onConfirm = (e) => {
    setIsLoading(true);
    const { meals, _id } = form;
    api
      .updateApplication({ applicationId: _id, meals })
      .then(() => {
        api
          .confirmApplication({ applicationId: _id })
          .then(() => closePopup())
          .catch((err) =>
            err.json().then(({ message }) => setConfirmError(message))
          )
          .finally(() => setIsLoading(false));
      })
      .catch((err) => console.log(err));
  };

  const exportApplication = () => {
    api
      .exportApplication(form._id)
      .then(({ path }) => window.open(path, "_blank"));
  };

  useEffect(() => {
    if (!success) return;
    setTimeout(() => setSuccess(false), 3000);
  }, [success]);

  return (
    <>
      <Modal show={true} onHide={() => closePopup()} fullscreen={true}>
        <Modal.Header closeButton>
          <Modal.Title>
            {form.dateFrom !== ""
              ? new Date(form.dateFrom).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : ""}
            {" - "}
            {form.dateTo !== ""
              ? new Date(form.dateTo).toLocaleDateString("ru-RU", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })
              : ""}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {success ? (
              <p className="text-success text-center fs-4">
                Изменения сохранены
              </p>
            ) : (
              ""
            )}
            <Form.Group className="mb-3">
              <Form.Label>Дата для заказов с</Form.Label>
              <DatePicker
                selected={new Date(form.dateFrom)}
                className="form-control"
                disabled={form.meals.length > 0}
                locale="ru"
                dateFormat="dd.MM.yyyy"
                onChange={(dateFrom) => setForm({ ...form, dateFrom })}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Дата для заказов </Form.Label>
              <DatePicker
                className="form-control"
                locale="ru"
                disabled={form.meals.length > 0}
                dateFormat="dd.MM.yyyy"
                selected={new Date(form.dateTo)}
                onChange={(dateTo) => setForm({ ...form, dateTo })}
              />
            </Form.Group>
            {form._id !== "" ? (
              <Form.Group className="mb-2">
                <Form.Label>Имя</Form.Label>
                <Form.Select value={form.owner} disabled={true}>
                  {users.map((el) => (
                    <option value={el._id} key={el._id}>
                      {el.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            ) : (
              ""
            )}

            {form.status === "notOrdered" ? (
              <Button
                variant="outline-primary mb-2"
                onClick={(e) => onRequestMeals()}
                disabled={isLoading}
              >
                {isLoading ? "Запрашиваем..." : "Запросить информацию"}
              </Button>
            ) : (
              ""
            )}
          </Form>
          {form.meals.length > 0 ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Название</th>
                  <th>Количество</th>
                  <th>Цена</th>
                  <th>Сумма</th>
                </tr>
              </thead>
              <tbody>
                {form.meals.map((meal) => (
                  <tr
                    className={`${meal.isError ? " bg-danger" : ""}${
                      meal.isSent ? " bg-primary" : ""
                    }`}
                  >
                    <td>
                      <p className="m-0">
                        {meal.name}{" "}
                        {meal.isSent ? (
                          ""
                        ) : (
                          <i
                            class="bi bi-x-lg ms-2"
                            onClick={() => mealRemove(meal.name)}
                            style={{ cursor: "pointer" }}
                          />
                        )}
                      </p>
                      {meal.isError ? (
                        <p className="m-0 fw-light">
                          Заказывали: {meal.owners.join(", ")}
                        </p>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>{meal.count}</td>
                    <td>{meal.price}</td>
                    <td>{meal.price * meal.count}</td>
                  </tr>
                ))}
                <tr>
                  <td colSpan={3}>Итого</td>
                  <td>{total}</td>
                </tr>
              </tbody>
            </Table>
          ) : (
            ""
          )}
        </Modal.Body>
        <Modal.Footer>
          {form._id !== "" ? (
            <Button
              variant="outline-secondary"
              onClick={() => exportApplication()}
            >
              Экспортировать в Excel
            </Button>
          ) : (
            ""
          )}

          {form._id !== "" ? (
            <Button variant="danger" onClick={(e) => setIsDeleting(true)}>
              Удалить
            </Button>
          ) : (
            ""
          )}

          <Button variant="outline-secondary" onClick={() => closePopup()}>
            Закрыть
          </Button>
          {form.status === "notOrdered" ? (
            <>
              {form.meals.length > 0 ? (
                <Button
                  variant="primary"
                  onClick={(e) => onSubmit(e)}
                  disabled={isLoading}
                >
                  {isLoading ? "Сохраняем..." : "Сохранить"}
                </Button>
              ) : (
                ""
              )}
              {(form.meals
                .filter((el) => !el.isSent)
                .filter((el) => !el.isError).length >
                0) &
              teamContext.menuInclude.mealty ? (
                <Button
                  variant={mealtyError !== "" ? "danger" : "primary"}
                  onClick={(e) => onSubmitAndSend(e)}
                  disabled={isLoading || mealtyError !== ""}
                >
                  {isLoading
                    ? "Сохраняем..."
                    : mealtyError
                    ? mealtyError
                    : "Сохранить и отправить в корзину Милти"}
                </Button>
              ) : (
                ""
              )}
              {(form.meals.length > 0) &
              (form.meals.filter((el) => el.isError).length === 0) &
              (form._id !== "") ? (
                <Button
                  variant={confirmError !== "" ? "danger" : "primary"}
                  onClick={(e) => onConfirm(e)}
                  disabled={isLoading || confirmError !== ""}
                >
                  {isLoading
                    ? "Подтверждаем..."
                    : confirmError
                    ? confirmError
                    : "Подтвердить заявку"}
                </Button>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Modal.Footer>
      </Modal>
      <Modal show={isDeleting} onHide={() => setIsDeleting(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Вы уверены?</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => setIsDeleting(false)}
          >
            Закрыть
          </Button>
          <Button variant="danger" onClick={(e) => onDelete(e)}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
