import React from 'react'
import { Button, Card } from 'react-bootstrap';
import smallLogo from "../media/small_logo.svg";

export default function MealGrid({deleteFromFavorite, addToFavorite, meal, setMeal, onAdd}) {
  return (
    <Card
    className="d-flex flex-column align-items-center p-3 m-2 pt-0"
    style={{ width: 150, minHeight: 150 }}
  >
    <Button
      variant="otline-secondary position-absolute text-white"
      style={{
        top: 5,
        right: 5,
        borderRadius: "50%",
        padding: "2px 7px",
      }}
      onClick={() => {
        if (meal.isFavorite) deleteFromFavorite(meal._id);
        else addToFavorite(meal._id);
      }}
    >
      {meal.isFavorite ? (
        <i className="bi bi-bookmark-star-fill fs-2" />
      ) : (
        <i className="bi bi-bookmark-star fs-2" />
      )}
    </Button>
    <img
      src={meal.image ? meal.image : smallLogo}
      alt={meal.name}
      className="rounded float-start"
      style={{ width: 150 }}
    />
    <p
      className="m-0 text-center mt-2 text-primary fw-bold"
      onClick={() => setMeal(meal)}
      style={{ cursor: "pointer" }}
    >
      {meal.name}
    </p>
    <p
      className="m-0 text-center text-primary fs-5"
      onClick={() => setMeal(meal)}
      style={{ cursor: "pointer" }}
    >
      {meal.subName}
    </p>
    <p className="m-0 text-center mb-2">{meal.price} р.</p>
    <Button
      variant="outline-primary"
      className="mt-auto"
      onClick={() =>
        onAdd({
          name: meal.name,
          price: meal.price,
          mealId: meal._id,
        })
      }
    >
      Добавить
    </Button>
  </Card>
  )
}
